import React, { useEffect, useState } from 'react';
// import { useHistory } from "react-router-dom";

import images from 'assets/Image';
import { disabledInspect, emailRegex } from "utils/index";
import { Service } from "config/service";

import { Container, TextField, Link, Typography, Button, Grid } from '@material-ui/core';
import { } from '@material-ui/icons';
import Layout from 'layout/Layout';

// *Import Component


function Login() {

  // const history = useHistory();

  const [form, setForm] = useState(true);

  // *For Login
  const [loginObj, setLoginObj] = useState({
    email: '',
    password: ''
  })

  // *For SignUp
  const [signUpObj, setSignUpObj] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: ''
  })

  const getForm = (type) => {
    if (type === true) {
      setForm(true);
    } else {
      setForm(false);
    }
  };

  // *Reset Form
  const resetForm = () => {
    setLoginObj({
      email: '',
      password: '',
    });
    setSignUpObj({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: ''
    });
  }

  // *For Login
  const login = async () => {
    try {
      if (loginObj.email === '' || loginObj.password === '') {
        return;
      } else {
        if (loginObj.email.match(emailRegex)) {
          const { data } = await Service.login(loginObj);
          localStorage.setItem('userName', data.userName);
          localStorage.setItem('token', data.token);
          // history.push('/');
          resetForm();
        } else {
          return;
        }
      }
    } catch (error) {
      resetForm();
      console.log('Login -> error', error);
    }
  };

  const loginHandle = (prop) => (event) => {
    setLoginObj({ ...loginObj, [prop]: event.target.value });
  };

  // *For SignUp
  const signUp = async () => {
    try {
      if (signUpObj.email === '' || signUpObj.password === '') {
        return;
      } else {
        if (signUpObj.email.match(emailRegex)) {
          const { data } = await Service.register(signUpObj);
          localStorage.setItem('userName', data.userName);
          localStorage.setItem('token', data.token);
          // history.push('/');
          resetForm();
        } else {
          return;
        }
      }
    } catch (error) {
      resetForm();
      console.log('SignUp -> error', error);
    }
  };

  const signUpHandle = (prop) => (event) => {
    setSignUpObj({ ...signUpObj, [prop]: event.target.value });
  };

  useEffect(() => {
    disabledInspect();
    window.scrollTo({ top: 0 });
  }, [])


  return (
    <div id="Login">

      <Layout>

        {/* ========== Login ========== */}
        <div className="login">
          {/* <div className="overlay"></div> */}
          <Container maxWidth="md">
            <Grid container spacing={8} justifyContent="center" >

              <Grid item md={7}>
                <div className="form-wrapper">
                  {form &&
                    <form method="POST">

                      <img src={images.logo} alt="logo" />
                      <Typography variant="h4">SIGN IN </Typography>
                      <Typography variant="body1" component="p">Enter your email address to access your saved designs, track your orders, and place a reorder!</Typography>
                      <TextField
                        type={'email'}
                        placeholder="Email"
                        value={loginObj.email}
                        onChange={loginHandle('email')}
                        required
                        error={loginObj.email !== "" && !loginObj.email.match(emailRegex)}
                        helperText={loginObj.email !== "" && !loginObj.email.match(emailRegex) ? 'Enter a valid email address' : ''}
                        variant="outlined"
                      />
                      <TextField
                        type={'password'}
                        placeholder="Password"
                        value={loginObj.password}
                        onChange={loginHandle('password')}
                        required
                        error={loginObj.password === ""}
                        helperText={loginObj.password === "" ? 'Password is required' : ''}
                        variant="outlined"
                      />
                      <Typography className="register-link">Don't have an account?
                        <Button onClick={() => { getForm(false); }}>Sign Up</Button>
                      </Typography>

                      <Button variant="outlined" className="sign-in-btn" onClick={() => { login() }}>
                        Sign In
                      </Button>

                    </form>
                  }
                  {form === false &&
                    <form method="POST">

                      <img src={images.logo} alt="logo" />
                      <Typography variant="h4">SIGN UP </Typography>
                      <TextField
                        type={'text'}
                        placeholder="First Name"
                        value={signUpObj.first_name}
                        onChange={signUpHandle('first_name')}
                        required
                        error={signUpObj.first_name === ""}
                        helperText={signUpObj.first_name === "" ? 'First name is required' : ''}
                        variant="outlined"
                      />
                      <TextField
                        type={'text'}
                        placeholder="Last Name"
                        value={signUpObj.last_name}
                        onChange={signUpHandle('last_name')}
                        required
                        error={signUpObj.last_name === ""}
                        helperText={signUpObj.last_name === "" ? 'Last name is required' : ''}
                        variant="outlined"
                      />
                      < TextField
                        type={'email'}
                        placeholder="Email"
                        value={signUpObj.email}
                        onChange={signUpHandle('email')}
                        required
                        error={signUpObj.email !== "" && !signUpObj.email.match(emailRegex)}
                        helperText={signUpObj.email !== "" && !signUpObj.email.match(emailRegex) ? 'Enter a valid email address' : ''}
                        variant="outlined"
                      />
                      < TextField
                        type={'password'}
                        placeholder="Password"
                        value={signUpObj.password}
                        onChange={signUpHandle('password')}
                        required
                        error={signUpObj.password === ""}
                        helperText={signUpObj.password === "" ? 'Password is required' : ''}
                        variant="outlined"
                      />
                      < TextField
                        type={'password'}
                        placeholder="Confirm Password"
                        value={signUpObj.confirm_password}
                        onChange={signUpHandle('confirm_password')}
                        required
                        error={signUpObj.confirm_password === ""}
                        helperText={signUpObj.confirm_password === "" ? 'Confirm Password is required' : ''}
                        variant="outlined"
                      />

                      <Typography className="register-link">
                        <Button onClick={() => { getForm(true); }}>Back to Sign In</Button>
                      </Typography>

                      <Button variant="outlined" className="sign-in-btn" onClick={() => { signUp() }}>
                        Sign Up
                      </Button>

                    </form>
                  }
                </div>
              </Grid>

            </Grid>
          </Container>
        </div>
      </Layout>

    </div>
  );
}

export default Login;