import { Breadcrumbs } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { colors } from 'style/colors'

function Breadcrumb(pathname) {
  const path = pathname.path.split("/")
  let value = []
  for (let index = 0; index < path.length - 2; index++) {
    if (value.length > 0) {
      let element = value.slice(0, index) + "/" + path[index + 2];
      value.push(element)
    }
    else {
      // let element = "/" + path[index + 1] + "/" + path[index + 2];
      let element = "/"
      value.push(element)
    }
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {[...Array(Number(path.length) - 1)].map((item, index) => (
        <Link key={index} to={value[index] ? value[index] : ""}
          style={{ textDecoration: "none", color: colors.darkGray, fontWeight: "bold", fontSize: "18px" }}>
          {path[index + 1].charAt(0).toUpperCase() + path[index + 1].slice(1).toLowerCase()}
        </Link>
      ))
      }
    </Breadcrumbs >
  )
}

export default Breadcrumb