import React, { useState } from 'react'
import { FilterAlt, StarRate } from '@mui/icons-material'
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material'
// import { Grid } from '@material-ui/core'
import { useLocation, useNavigate } from 'react-router-dom'

//* Imported Components
import { colors } from 'style/colors'
import Images from 'assets/Images'
import Filter from 'component/Filter/Filter'
import Breadcrumb from 'component/Breadcrumb/Breadcrumb'
import SearchFilter from 'component/SearchFilter/SearchFilter'
import { SortButton } from 'component/UI/Button'
import ColorBox from 'component/UI/ColorBox'
import Layout from 'layout/Layout'

function SubProduct() {

  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Filter Side Nav Control
  const [selectedFilter, setSelectedFilter] = useState(true)
  const handleChangeFilter = () => {
    setSelectedFilter(!selectedFilter);
  }

  return (
    <Layout>
      <Container>
        <Box sx={{ p: 2, my: 3, border: `2px solid ${colors.black}`, borderRadius: 2 }}>
          {/* Breadcrumbs */}
          <Breadcrumb path={pathname} />
        </Box>
        <Box sx={{ py: 1.5, px: 2, boxShadow: `rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px` }}>
          <SearchFilter />
          <Divider sx={{ my: 1, backgroundColor: colors.platinum, }} />
          <Box sx={{ display: "flex", algnItems: "center" }}>
            <Button startIcon={<FilterAlt sx={{ fontSize: '34px' }} />}
              onClick={handleChangeFilter}
              sx={{ backgroundColor: colors.platinum, px: 2, py: 1, fontSize: "16px", mr: 2 }}
            >
              Filters
            </Button>
            <SortButton myStyle={{ backgroundColor: colors.platinum, py: 1.5, px: 2 }} />
          </Box>
        </Box>
        <Grid container sx={{ justifyContent: "space-between", my: 6 }} >
          {selectedFilter === true &&
            <Grid item xs={12} md={3}>
              <Filter />
            </Grid>
          }
          <Grid item xs={12} md={selectedFilter ? 8.8 : 12} container columnSpacing={2} rowGap={2}>
            {[...Array(Number(8))].map((item, index) => (
              <Grid item xs={12} md={selectedFilter ? 4 : 3}>
                <Box sx={{ boxShadow: `rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`, cursor: "pointer" }}
                  onClick={() => navigate("/products/t-shirts/short-sleeves-shirt/promotional-performance-shirts")}
                >
                  <img src={Images.productCardImage} width="100%" alt="Product Card" />
                  <Box sx={{ textAlign: "center", cursor: "pointer" }}>
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold", color: colors.black, px: 3, pb: 1 }}>A4 Promotional Performance Shirt</Typography>
                    <Box>
                      {[...Array(Number(5))].map((item, index) => (
                        <StarRate sx={{ color: "#f9a60f" }} />
                      ))}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ color: colors.darkGray }}>YS-3XL</Typography>
                      <Divider orientation='vertical' sx={{
                        color: colors.darkGray, height: "24px", mx: 1
                      }} />
                      <Typography sx={{ color: colors.darkGray }
                      } > Minimum 6</Typography>
                    </Box>
                    <Typography sx={{ color: colors.black, fontWeight: "bold" }}>$14.69 each for 25 items</Typography>
                    <Typography sx={{ color: colors.black, fontWeight: "bold" }}>ALSO IN WOMENS</Typography>
                  </Box>
                  <Box sx={{ px: 2, pb: 1 }}>
                    <ColorBox />
                  </Box>
                </Box>

              </Grid>
            ))}

          </Grid>
        </Grid>
      </Container>
    </Layout >
  )
}

export default SubProduct