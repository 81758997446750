import React, { Fragment, useEffect, useState } from 'react'
import NoticeHeader from '../NoticeHeader/NoticeHeader'
import Typography from '@mui/material/Typography'
import { Box, Container, Grid, InputAdornment, TextField, Badge, Avatar, ListItemIcon, Menu, MenuItem, Divider, Button } from '@mui/material'
import { FavoriteBorder, Search, ShoppingCartOutlined } from '@mui/icons-material'


import { colors } from 'style/colors'
import images from 'assets/Image'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { selectTotalAddToCart } from 'features/counter/counterSlice';
import { PrimaryButton, SecondaryButton } from 'component/UI/Button'
import useAuth from './../../hooks/useAuth';
import { ListAlt, Person } from '@material-ui/icons'

const useStyle = makeStyles({
  headerFixed: {
    position: "fixed",
    top: "0",
    left: "0",
    // transform: "translate(-50%,0)",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "#fff",
  },
})

function Header() {
  //*Account Hover Section Constant*/
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  localStorage.getItem("NoOfDesign")
  const totalAddToCart = useSelector(selectTotalAddToCart);
  const navigate = useNavigate();
  const classes = useStyle()
  const [scrollPosition, setPosition] = useState(0);
  const { user, logout } = useAuth();
  const token = localStorage.getItem("jwt")

  useEffect(() => {
    function updatePosition() {
      setPosition(window.scrollY);
    }
    window.addEventListener("scroll", updatePosition);
  }, []);

  return (
    <Fragment>

      <NoticeHeader />

      {/* sub header */}
      {/* <Box sx={{ bgcolor: colors.lightGray, py: 1.5 }} >
        <Container maxWidth="lg">
          <Grid container columnSpacing={3} justifyContent="space-between"> */}
      {/* left side */}
      {/* <Grid container columnSpacing={2} item xs={5.5}>
              <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                <AddIcCallOutlined sx={{ color: colors.darkGray, fontSize: "17px" }} />
                <Typography variant="body2" sx={{ fontSize: "12px", ml: 1, color: colors.darkGray }} >+1 800 381 0815</Typography>
                <Divider sx={{ bgcolor: colors.darkGray, height: "16px", p: 0.05, ml: 3 }} />
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                <AutorenewOutlined sx={{ color: colors.darkGray, fontSize: "17px" }} />
                <Typography variant="body2" sx={{ fontSize: "12px", ml: 1, color: colors.darkGray }} >30 day return policy</Typography>
                <Divider sx={{ bgcolor: colors.darkGray, height: "16px", p: 0.05, ml: 2 }} />
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                <PercentOutlined sx={{ color: colors.darkGray, fontSize: "17px" }} />
                <Typography variant="body2" sx={{ fontSize: "12px", ml: 1, color: colors.darkGray }} >Save up to 70%</Typography>
              </Grid>
            </Grid> */}

      {/* right side */}
      {/* <Grid container columnSpacing={2} item xs={5}>
              <Grid item xs={6} >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}> */}
      {/* <Button variant='outline' component={Link} to="/design-tool" sx={{ fontSize: "12px", backgroundColor: colors.platinum }} >Design Lab</Button> */}
      {/* <Button variant="contained" startIcon={<WarehouseOutlined />} sx={{ fontSize: "12px", backgroundColor: colors.primary }}>Start Selling</Button>
                </Box>
              </Grid>
              <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                <BusinessCenterOutlined sx={{ color: colors.darkGray, fontSize: "17px" }} />
                <Typography variant="body2" sx={{ fontSize: "12px", ml: 1, color: colors.darkGray }} >Jobs</Typography>
                <Divider sx={{ bgcolor: colors.darkGray, height: "16px", p: 0.05, ml: 2 }} />
              </Grid>
              <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                <QuestionMarkOutlined sx={{ color: colors.darkGray, fontSize: "17px" }} />
                <Typography variant="body2" sx={{ fontSize: "12px", ml: 1, color: colors.darkGray }} >Help</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box> */}

      <Box sx={{ bgcolor: colors.white, py: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px", zIndex: 999 }}
        className={
          scrollPosition > 10 && classes.headerFixed}
      >
        <Container maxWidth="lg">
          <Grid container alignItems="center" justifyContent="space-between">

            {/* left side */}
            <Grid item xs={6} container>
              <Grid item xs={4}>
                <img src={images.logo} alt="" width="100%" style={{ cursor: "pointer" }} onClick={() => { navigate('/') }} />
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ textAlign: "center", cursor: "pointer" }} onClick={() => { navigate('/design-tool') }}>
                  <Typography variant="body1" fontWeight="bold" color={colors.primary}>Create</Typography>
                  <Typography variant="body2" color="gray">Custom Products</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="body1" color={colors.primary} fontWeight="bold">Shop</Typography>
                  <Typography variant="body2" color="gray">Marketplace Designs</Typography>
                </Box>
              </Grid>
            </Grid>

            {/* right side */}
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={7.5}>
                {/* <Box sx={{ textAlign: "center" }}>
                  <TextField label="Find Products"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined" />
                </Box> */}
              </Grid>
              <Grid item xs={2} >
                <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                  <FavoriteBorder sx={{ ml: 1 }} />
                  {totalAddToCart.length > 0 ? (
                    <Badge badgeContent={totalAddToCart.length} color="primary" sx={{ cursor: "pointer" }}>
                      <ShoppingCartOutlined onClick={() => { navigate('/cart') }} sx={{ ml: 1, }} />
                    </Badge>
                  ) : (
                    <ShoppingCartOutlined onClick={() => { navigate('/cart') }} sx={{ ml: 1, }} />
                  )}

                </Box>
              </Grid>
              <Grid item xs={2} mr={1} >
                {user || token ? (
                  <Box sx={{ cursor: "pointer" }} >
                    <Avatar sx={{ backgroundColor: colors.primary }} onClick={(e) => { handleClick(e) }} />
                  </Box>
                ) : (
                  <Box sx={{ cursor: "pointer" }} >
                    <SecondaryButton onClick={() => { navigate('/login') }}>Login</SecondaryButton>
                  </Box>
                )}

              </Grid>
            </Grid>

          </Grid>
        </Container>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: '350px',
            px: 2,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 60,
              height: 60,
              ml: 1,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Typography sx={{
          fontSize: '16px', fontWeight: 500, color: colors.black,
          p: 1.5
        }}>User Profile</Typography>
        <Box sx={{ display: 'flex', alignItems: "center", cursor: "pointer" }}>
          <Avatar src={images.userProfile} sx={{ borderRadius: '50px' }} />
          <Box>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: "bold"
              }}>Mr Johnathan</Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: colors.textSecondary
              }}>admin@gmail.com</Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 1 }} />
        <MenuItem sx={{ p: 1, borderRadius: "8px" }} >
          <Box sx={{ display: 'flex' }}>
            <Button sx={{
              bgcolor: colors.primaryLight,
              mr: 1,
              borderRadius: '10px',
              '&:hover': {
                bgcolor: colors.primaryLight,
              }
            }}><Person fontSize="small" />
            </Button>
            <Box >
              <Typography
                sx={{
                  fontSize: '1rem',
                }}>My Profile</Typography>
              <Typography variant="h6"
                sx={{
                  fontSize: '0.875rem',
                  color: colors.textSecondary
                }}>Account Settings</Typography>
            </Box>
          </Box>
        </MenuItem>
        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={() => { navigate('/order-details') }} sx={{ p: 1, borderRadius: "8px" }} >
          <Box sx={{ display: 'flex' }} >
            <Button
              sx={{
                bgcolor: colors.primaryLight,
                mr: 1,
                borderRadius: '10px',
                '&:hover': {
                  bgcolor: colors.primaryLight,
                }
              }}><ListAlt fontSize="small" />
            </Button>
            <Box>
              <Typography variant="h5"
                sx={{
                  fontSize: '1rem'
                }}>Orders</Typography>
              <Typography variant="h6"
                sx={{
                  fontSize: '0.875rem',
                  color: colors.textSecondary
                }}>Order Details</Typography>
            </Box>
          </Box>
        </MenuItem>
        <PrimaryButton onClick={() => logout()} fullWidth myStyle={{ my: 2 }}>
          Logout
        </PrimaryButton>
      </Menu>
    </Fragment>
  )
}

export default Header