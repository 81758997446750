import React, { Fragment } from 'react'
import { TabPanel } from '@material-ui/lab';
import { IconButton, List, Typography, TextField, Grid, Divider, Slider, Input, ButtonGroup, CardMedia, Button } from '@material-ui/core';
import { Close, ArrowForwardIos, VerticalAlignCenter, ArrowBackIos } from '@mui/icons-material';
import images from './../../assets/Image';
import PropagateLoader from 'react-spinners/PropagateLoader';

function AddTextTab({ textPanel, fontEditPanel, changePanel, setTextValue, addToDesign, handleTextFontSize,
  frontTextInput, backTextInput, frontFontFamily, backFontFamily, frontFontOutlineColor, backFontOutlineColor,
  frontTextRotate, backTextRotate, setTextRotateValue, blurRotate, register, valueChangeOfText, textAlignCenter,
  textLayer, fontFamilyPanel, getTextEditPanel, fonts, baseURL, changeFontFamily, fontColorPanel, colorLoader, loaderColor,
  opacity, productAngle, frontFontColor, backFontColor, colors, changeFontColor, fontOutlinePanel, frontTextOutlineThickness,
  backTextOutlineThickness, handleThickness, rotateValueLabel, rotateValueText, rotateValue, changeFontOutlineColor
}) {
  return (
    <Fragment>
      <TabPanel className="text-panel" value="addText">
        <List component="nav" className="wrapper">
          {textPanel && (
            <div>
              {/* ========== Add Text Tab ========== */}
              {fontEditPanel && (
                <div className="header-title">
                  <IconButton
                    aria-label="close"
                    className="close-btn"
                    onClick={() => changePanel("", "main")}
                  >
                    <Close />
                  </IconButton>
                  <Typography
                    variant="body1"
                    component="h1"
                    align="center"
                  >
                    Add Text
                  </Typography>
                </div>
              )}

              {fontEditPanel && (
                <div>
                  <TextField
                    onChange={(e) => setTextValue(e, "add")}
                    label="Enter Text Here"
                    variant="outlined"
                    multiline
                  />
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      addToDesign();
                      handleTextFontSize();
                    }}
                  >
                    Add To Design
                  </Button>
                </div>
              )}

              {/* ========== Edit Text Panel ========== */}
              {fontEditPanel === false && (
                <div className="header-title">
                  <IconButton
                    aria-label="close"
                    className="close-btn"
                    onClick={() => changePanel("", "main")}
                  >
                    <Close />
                  </IconButton>
                  <Typography
                    variant="body1"
                    component="h1"
                    align="center"
                  >
                    Edit Text
                  </Typography>
                </div>
              )}

              {fontEditPanel === false && (
                <div className="edit-tool-content">
                  <TextField
                    value={
                      productAngle === "front"
                        ? frontTextInput
                        : backTextInput
                    }
                    id="testingText"
                    onChange={(e) => setTextValue(e, "edit")}
                    label="Enter Text Here"
                    variant="outlined"
                    multiline
                  />

                  {/* ========== Font Family ========== */}
                  <Grid
                    container
                    spacing={2}
                    className="change-font"
                    onClick={() => {
                      getTextEditPanel(false, true, false, false);
                    }}
                  >
                    <Typography variant="body1" component="h6">
                      Font
                    </Typography>
                    <Typography variant="body1">
                      {productAngle === "front"
                        ? frontFontFamily
                        : backFontFamily}
                    </Typography>
                    <ArrowForwardIos />
                  </Grid>

                  <Divider />

                  {/* ========== Font Color ========== */}
                  <Grid
                    container
                    spacing={0}
                    className="change-color"
                    onClick={() => {
                      getTextEditPanel(false, false, true, false);
                    }}
                  >
                    <Grid item md={6}>
                      <Typography variant="body1" component="h6">
                        Text Color
                      </Typography>
                    </Grid>

                    <Grid item md={6} style={{ textAlign: "right" }}>
                      <Typography
                        component="span"
                        className="image-color"
                        style={{
                          backgroundColor:
                            productAngle === "front"
                              ? frontFontColor
                              : backFontColor,
                        }}
                      />
                      <ArrowForwardIos />
                    </Grid>
                  </Grid>

                  <Divider />

                  {/* ========== Font Outline ========== */}
                  <Grid
                    container
                    spacing={0}
                    className="change-color"
                    onClick={() => {
                      getTextEditPanel(false, false, false, true);
                    }}
                  >
                    <Grid item md={6}>
                      <Typography variant="body1" component="h6">
                        Text Outline
                      </Typography>
                    </Grid>

                    <Grid item md={6} style={{ textAlign: "right" }}>
                      <Typography
                        component="span"
                        className="image-color"
                        style={{
                          backgroundColor:
                            productAngle === "front"
                              ? frontFontOutlineColor
                              : backFontOutlineColor,
                        }}
                      />
                      <ArrowForwardIos />
                    </Grid>
                  </Grid>
                  <Divider />
                  {/* ========== Font Rotation ========== */}
                  <Grid container spacing={2} className="rotation">
                    <Grid container item md={3} justifyContent="center">
                      <Typography variant="body1" component="h6">
                        Rotation
                      </Typography>
                    </Grid>

                    <Grid container item md={7} justifyContent="center">
                      <Slider
                        value={
                          productAngle === "front"
                            ? frontTextRotate
                            : backTextRotate
                        }
                        onChange={setTextRotateValue}
                        valueLabelDisplay="auto"
                        min={-180}
                        max={180}
                      />
                    </Grid>

                    <Grid container item md={2} justifyContent="center">
                      <Input
                        value={
                          productAngle === "front"
                            ? frontTextRotate
                            : backTextRotate
                        }
                        onChange={(e) =>
                          setTextRotateValue(e, e.target.value)
                        }
                        onBlur={blurRotate}
                        inputProps={{
                          step: 1,
                          min: -180,
                          max: 180,
                          type: "number",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  {/* ========== Font Size ========== */}
                  <Grid container spacing={0} className="text-size">
                    <Grid item md={8}>
                      <Typography variant="body1" component="h6">
                        Text Size
                      </Typography>
                    </Grid>

                    <Grid container item md={4} justifyContent="flex-end">
                      <TextField
                        variant="outlined"
                        type="number"
                        // defaultValue={productAngle === 'front' ? textFontSize : backTextSize}
                        {...register("text_value_size")}
                        // defaultValue={textFontSize}
                        onChange={(e) => {
                          valueChangeOfText(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Divider />

                  {/* ========== Font Alignment & layering ========== */}
                  <Grid container spacing={2}>
                    <Grid container item md={3} justifyContent="center">
                      <Button
                        className="center-btn"
                        onClick={() => textAlignCenter()}
                      >
                        <VerticalAlignCenter className="center-icon" />
                      </Button>
                      <Typography variant="caption">Center</Typography>
                    </Grid>

                    <Grid
                      container
                      item
                      md={5}
                      alignItems="center"
                      direction="column"
                    >
                      <ButtonGroup>
                        <Button onClick={() => textLayer(true)}>
                          <CardMedia
                            component="img"
                            height="25"
                            image={images.topLayer}
                          />
                        </Button>
                        <Button onClick={() => textLayer(false)}>
                          <CardMedia
                            component="img"
                            height="25"
                            image={images.bottomLayer}
                          />
                        </Button>
                      </ButtonGroup>
                      <Typography variant="caption">Layering</Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          )}

          {/* ========== Font Family Panel ========== */}
          {fontFamilyPanel && (
            <div className="font-panel">
              <div className="header-title">
                <IconButton
                  aria-label="back"
                  className="back-btn"
                  onClick={() => {
                    getTextEditPanel(true, false, false, false);
                  }}
                >
                  <ArrowBackIos />
                </IconButton>

                <IconButton
                  aria-label="close"
                  className="close-btn"
                  onClick={() => changePanel("", "main")}
                >
                  <Close />
                </IconButton>

                <Typography variant="body1" component="h1" align="center">
                  Select Font
                </Typography>
              </div>

              <List component="nav" className="font-category">
                {fonts.map((value, i) => (
                  <div className="pointer" align="center">
                    <img
                      key={i}
                      src={baseURL + value.family_title_path}
                      alt=""
                      onClick={() => {
                        changeFontFamily(value.family, value.type);
                      }}
                    />
                    {/* <Typography component="li" key={i}>{value.family}</Typography> */}
                  </div>
                ))}
              </List>
            </div>
          )}

          {/* ========== Font Color Panel ========== */}
          {fontColorPanel && (
            <div className="edit-tool-content">
              {/* ========== Color Loader ========== */}
              {colorLoader && (
                <div className="color-loader">
                  <div className="sweet-loading">
                    <PropagateLoader
                      color={loaderColor}
                      loading={true}
                      size={15}
                    />
                  </div>
                </div>
              )}
              <div style={{ opacity: opacity }}>
                <div className="header-title">
                  <IconButton
                    aria-label="back"
                    className="back-btn"
                    onClick={() => {
                      getTextEditPanel(true, false, false, false);
                    }}
                  >
                    <ArrowBackIos />
                  </IconButton>

                  <IconButton
                    aria-label="close"
                    className="close-btn"
                    onClick={() => changePanel("", "main")}
                  >
                    <Close />
                  </IconButton>

                  <Typography
                    variant="body1"
                    component="h1"
                    align="center"
                  >
                    Font Color
                  </Typography>
                </div>
                <Grid
                  container
                  spacing={1}
                  className="change-color-panel"
                >
                  <Typography variant="body1" component="h6">
                    Select color
                  </Typography>
                  <Grid item md={12}>
                    <Typography
                      component="span"
                      className="image-color"
                      style={{
                        backgroundColor:
                          productAngle === "front"
                            ? frontFontColor
                            : backFontColor,
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    {colors.map((value, index) => (
                      <label className="color-box" key={index}>
                        <input type="radio" name="color" />
                        <span
                          className="checkmark"
                          style={{ backgroundColor: value.code }}
                          onClick={() => {
                            changeFontColor(value.code);
                            console.log("first time")
                          }}
                        ></span>
                      </label>
                    ))}
                  </Grid>
                </Grid>
              </div>
            </div>
          )}

          {/* ========== Font Outline Panel ========== */}
          {fontOutlinePanel && (
            <div className="edit-tool-content">
              <div className="header-title">
                <IconButton
                  aria-label="back"
                  className="back-btn"
                  onClick={() => {
                    getTextEditPanel(true, false, false, false);
                  }}
                >
                  <ArrowBackIos />
                </IconButton>

                <IconButton
                  aria-label="close"
                  className="close-btn"
                  onClick={() => changePanel("", "main")}
                >
                  <Close />
                </IconButton>

                <Typography variant="body1" component="h1" align="center">
                  Text Outline
                </Typography>
              </div>

              <Grid container spacing={1} className="change-color-panel">
                <Typography variant="body1" component="h6">
                  Choose outline thickness
                </Typography>
                <Grid item md={12}>
                  <Grid container spacing={2} className="rotation">
                    <Grid container item md={12} justifyContent="center">
                      <Slider
                        value={
                          productAngle === "front"
                            ? frontTextOutlineThickness
                            : backTextOutlineThickness
                        }
                        onChange={handleThickness}
                        valueLabelFormat={rotateValueLabel}
                        getAriaValueText={rotateValueText}
                        step={null}
                        marks={rotateValue}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Divider />

                <Typography variant="body1" component="h6">
                  Choose outline color
                </Typography>
                <Grid item md={12}>
                  {colors.map((value, index) => (
                    <label className="color-box" key={index}>
                      <input type="radio" name="color" />
                      <span
                        className="checkmark"
                        style={{ backgroundColor: value.code }}
                        onClick={() => {
                          changeFontOutlineColor(value.code);
                        }}
                      ></span>
                    </label>
                  ))}
                </Grid>
              </Grid>
            </div>
          )}
        </List>
      </TabPanel>
    </Fragment>
  )
}

export default AddTextTab