// *Import Components

import MyOrders from "pages/MyOrder/MyOrder"
import ViewOrderPage from './../component/ViewOrderPage/ViewOrderPage';


const PrivateRoutes = [
  {
    path: "/order-details",
    component: <MyOrders />,
  },
  {
    path: "/order-invoice/:id",
    component: <ViewOrderPage />
  },
]

export default PrivateRoutes