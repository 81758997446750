import React, { useState, useRef, useEffect } from 'react'
import Layout from './../../layout/Layout';
import { Box, Grid, Typography, IconButton, InputAdornment, OutlinedInput, InputLabel, FormControl, TextField } from '@mui/material';
import images from '../../assets/Images';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { LoadingBtn } from 'component/UI/Button';
import { colors } from './../../style/colors';
import { Error } from 'component/UI/Error';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ErrorToaster, SuccessToaster } from 'component/Toaster/toaster';
import useAuth from './../../hooks/useAuth';
import { Service } from 'config/service';
import Swal from 'sweetalert2';

function Register() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  // *Registration 
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  // For API Call
  const registerInfo = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        password: formData.password,
        confirm_password: formData.confirm_password
      }

      const { status, data, responseCode, message } = await Service.register(obj)
      if (status === true && responseCode === 200) {
        // navigate('/login')
        setLoading(false)
        SuccessToaster("Register Successfully")
        login(data.token)
      }
      if (status === true && responseCode === 404) {
        Swal.fire({
          text: message,
          icon: 'error',
          confirmButtonColor: colors.primary,
        })
      }

    } catch (error) {
      console.log("🚀 ~ file: Register.js ~ line 52 ~ registerInfo ~ error", error)
      ErrorToaster("Something went wrong.")
    } finally {
      setLoading(false)
    }
  };

  // const registerInfo = async (formData) => {
  //   setLoading(true)
  //   SuccessToaster("Register Successfully")
  //   setTimeout(() => {
  //     setLoading(false)
  //     navigate('/login')
  //   }, 2100)
  // }

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  return (
    <Layout>
      <Box className="login" sx={{
        display: "flex", alignItems: "center", textAlign: "center",
        backgroundImage: `url(${images.loginBg})`, minHeight: "75vh",
      }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={4} sx={{ border: `1px solid black`, borderRadius: "12px", my: 4 }}>
            <Box sx={{
              textAlign: "center", px: 3, py: 4, backgroundColor: colors.white, borderRadius: "10px",
            }}>
              <img src={images.logo} width="50%" alt="Logo" />
              <Typography variant="h5"
                sx={{ color: colors.black, mt: 3 }}>
                Registration
              </Typography>
              <Typography component={"form"} onSubmit={handleSubmit(registerInfo)}>
                <Grid container>
                  {/* First Name */}
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        variant="outlined"
                        label="First name"
                        type="text"
                        error={errors?.first_name?.message && (true)}
                        {...register("first_name", {
                          required: 'Please enter your First name.',
                        })}
                      />
                      {errors?.first_name?.message && (
                        <Error message={errors?.first_name?.message} />
                      )}
                    </FormControl>
                  </Grid>
                  {/* Last Name */}
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        variant="outlined"
                        label="Last name"
                        type="text"
                        error={errors?.last_name?.message && (true)}
                        {...register("last_name", {
                          required: 'Please enter your Last name.',
                        })}
                      />
                      {errors?.last_name?.message && (
                        <Error message={errors?.last_name?.message} />
                      )}
                    </FormControl>
                  </Grid>
                  {/* Email */}
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        variant="outlined"
                        label="Email"
                        type="email"
                        error={errors?.email?.message && (true)}
                        {...register("email", {
                          required: 'Please enter your email.',
                        })}
                      />
                      {errors?.email?.message && (
                        <Error message={errors?.email?.message} />
                      )}
                    </FormControl>
                  </Grid>
                  {/* Password */}
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <InputLabel error={errors?.password?.message && (true)}>Password</InputLabel>
                      <OutlinedInput
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => { handleClickShowPassword() }}
                              edge="end"
                              sx={{ color: colors.primary }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        error={errors?.password?.message && (true)}
                        {...register("password", {
                          required: 'Please create your password.',
                        })}
                      />
                      {errors?.password?.message && (
                        <Error message={errors?.password?.message} />
                      )}
                    </FormControl>
                  </Grid>
                  {/* Confirm Password */}
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <InputLabel error={errors?.confirm_password?.message && (true)}>Confirm Password</InputLabel>
                      <OutlinedInput
                        label="Confirm Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => { handleClickShowConfirmPassword() }}
                              edge="end"
                              sx={{ color: colors.primary }}
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        error={errors?.confirm_password?.message && (true)}
                        {...register("confirm_password", {
                          required: 'Please re-type your password.',
                          validate: value =>
                            value === password.current || "Confirm password does not match"
                        })}
                      />
                      {errors?.confirm_password?.message && (
                        <Error message={errors?.confirm_password?.message} />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Typography
                  onClick={() => { navigate('/login') }}
                  sx={{
                    color: colors.textSecondary, my: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  Already have an account ? <span style={{ color: colors.primary }}>Log in</span>
                </Typography>
                <LoadingBtn
                  fullWidth
                  variant="contained"
                  type="submit"
                  loading={loading}
                  sx={{
                    p: 1,
                    my: 1,
                    width: '100%'

                  }}
                >
                  Register
                </LoadingBtn>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default Register