import React from 'react'
import { colors } from 'style/colors';
import { Typography, Box, Grid, Divider, } from '@mui/material';
import Images from './../../assets/Images';
import { ArrowBack } from '@material-ui/icons';
import Layout from './../../layout/Layout';
import { PrimaryButton } from 'component/UI/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, } from "react-redux";
import { selectTotalAddToCart, } from 'features/counter/counterSlice';

function ViewOrderPage() {
  // Using Redux
  const totalAddToCart = useSelector(selectTotalAddToCart);
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("🚀 ~ file: ViewOrderPage.js ~ line 19 ~ ViewOrderPage ~ id", id)

  const orderDetails = {
    firstName: "Mr", lastName: "Johnathan", billingAddress: "522 Crona Way Apt. 821,522 Crona Way Apt. 821,522 Crona Way Apt. 821", shippingAddress: "522 Crona Way Apt. 821,522 Crona Way Apt. 821,522 Crona Way Apt. 821",
    postalCode: 7600, state: "Nazimabad", orderNumber: 1002, deliveryOptions: ['October 3, Mon', '8', '2.30'], city: "Karachi", country: "Pakistan", invoiceId: "10002", orderIssueDate: "22-Sep, 2022", designName: "My Design",
    totalPayment: 326.3, status: "Verified", subTotalItems: 12, subTotalItemPrice: 324,
  }

  // const [orderDetails, setOrderDetails] = useState('');

  // //* Get Order Details API
  // const getInvoiceDetails = async () => {
  //   try {
  //     const { Data } = await Service.getInvoiceDetails(id)
  //     setOrderDetails(Data)
  //   } catch (error) {
  //      console.log("🚀 ~ file: ViewOrderPage.js ~ line 33 ~ //getInvoiceDetails ~ error", error)
  //   }
  // }


  // useEffect(() => {
  //   getInvoiceDetails()
  // }, [id])


  return (
    <Layout>
      <Box sx={{ backgroundColor: colors.whiteSpace, py: 4, px: 4, height: "100%" }}>
        <Typography sx={{ textAlign: "center", fontSize: "32px", fontWeight: "bold", mb: 2 }}>Order Details</Typography>
        <Grid container spacing={2} >
          <Grid item xs={12} md={8}>
            <Grid container sx={{ border: `1px solid #e5e7ea`, p: 2, backgroundColor: colors.white }}>
              <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Billed To:</Typography>
                <Typography variant="body2" sx={{ color: colors.textSecondary }}>{orderDetails.firstName} {orderDetails.lastName}</Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Order Number:</Typography>
                <Typography variant="body2" sx={{ color: colors.textSecondary }}>{orderDetails.orderNumber}</Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Billing Address:</Typography>
                <Typography variant="body2" sx={{ color: colors.textSecondary }}>{orderDetails.billingAddress}</Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Shipping Address:</Typography>
                <Typography variant="body2" sx={{ color: colors.textSecondary }}>{orderDetails.shippingAddress}</Typography>
              </Grid>
            </Grid>
            {/* Product Details */}
            {[...Array(Number(totalAddToCart.length))].map((item, index) => (
              <Box sx={{ border: `1px solid #e5e7ea`, py: 4, px: 2, alignItems: "center", backgroundColor: colors.white, my: 2 }}>
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Design Name:</Typography>
                  <Typography variant="body2" sx={{ color: colors.textSecondary }}>{orderDetails.designName}</Typography>
                </Box>
                <Divider sx={{ my: 1.5 }} />
                <Box>
                  <Typography sx={{ fontSize: "12px" }}>
                    <span style={{ fontWeight: "bold", color: colors.textSecondary }}>PRODUCT: </span>
                    RT2000 by Design Tool Classic Tee
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
                    <Box>
                      <img src={Images.cartProductImage} style={{ width: "100px", marginRight: "8px" }} alt="Product Front" />
                      <img src={Images.cartProductImageBack} width="100px" alt="Product Back" />
                    </Box>
                    <Box sx={{ ml: 3, flexGrow: 1 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: "bold", mr: 1, color: colors.textSecondary }}>
                          Color:
                        </Typography>
                        <Typography sx={{ backgroundColor: colors.white, width: '18px', height: '18px', borderRadius: "4px", border: `1px solid black` }}></Typography>
                        <Typography sx={{ ml: 1, fontSize: "13px" }}>White</Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontWeight: "bold", color: colors.textSecondary }}>
                          Size:
                        </Typography>
                        {/* Adult Size */}
                        {totalAddToCart[index].adultSize &&
                          <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
                            <Typography sx={{ fontWeight: "bold", mr: 1, color: colors.textSecondary, fontSize: "13px" }}>
                              Adult Size:
                            </Typography>
                            {[...Array(Number(Object.keys(totalAddToCart[index].adultSize).length))].map((item, index2) => (
                              totalAddToCart[index].adultSize[Object.keys(totalAddToCart[index].adultSize)[index2]] !== 0 &&
                              <Box key={index2}>
                                <Typography sx={{ border: `1px solid ${colors.textSecondary}`, px: 0.5, boxSizing: 'border-box', color: colors.textSecondary, fontSize: "13px", mr: 0.5 }}>
                                  {Object.keys(totalAddToCart[index].adultSize)[index2]} - {totalAddToCart[index].adultSize[Object.keys(totalAddToCart[index].adultSize)[index2]]}</Typography>
                              </Box>
                            ))}
                          </Box>
                        }
                        {/* Youth Size */}
                        {totalAddToCart[index].youthSize &&
                          <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
                            <Typography sx={{ fontWeight: "bold", mr: 1, color: colors.textSecondary, fontSize: "13px" }}>
                              Youth Size:
                            </Typography>
                            {[...Array(Number(Object.keys(totalAddToCart[index].youthSize).length))].map((item, index3) => (
                              totalAddToCart[index].youthSize[Object.keys(totalAddToCart[index].youthSize)[index3]] !== 0 &&
                              <Box key={index3}>
                                <Typography sx={{ border: `1px solid ${colors.textSecondary}`, px: 0.5, boxSizing: 'border-box', color: colors.textSecondary, fontSize: "13px", mr: 0.5 }}>
                                  {Object.keys(totalAddToCart[index].youthSize)[index3]} - {totalAddToCart[index].youthSize[Object.keys(totalAddToCart[index].youthSize)[index3]]}</Typography>
                              </Box>
                            ))}
                          </Box>
                        }
                      </Box>
                    </Box>
                    <Box sx={{ alignSelf: 'flex-end' }}>
                      <Typography sx={{ color: colors.textSecondary, fontSize: "12px" }}>{totalAddToCart[index].totalItem} items</Typography>
                      <Typography sx={{ color: colors.textSecondary, fontSize: "12px" }}>${totalAddToCart[index].eachItemPrice} each</Typography>
                      <Typography sx={{ fontWeight: "bold", color: colors.black, fontSize: "14px" }}>${totalAddToCart[index].totalPrice}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}

          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ border: `1px solid #e5e7ea`, p: 2, alignItems: "center", backgroundColor: colors.white }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "18px", color: colors.black }}>Order Summary</Typography>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ mb: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Order Status:</Typography>
                <Typography variant="body2" color={orderDetails.status === "Verified" ? "green" : "red"} sx={{ fontWeight: "bold" }} >{orderDetails.status}</Typography>
              </Box>
              <Box sx={{ mb: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Order Issue Date:</Typography>
                <Typography variant="body2" sx={{ color: colors.textSecondary }}>{orderDetails.orderIssueDate}</Typography>
              </Box>
              <Box sx={{ mb: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Subtotal ({orderDetails.subTotalItems} Items):</Typography>
                <Typography variant="body2" sx={{ color: colors.textSecondary }}>${orderDetails.subTotalItemPrice}</Typography>
              </Box>
              {/* <Box sx={{ mb: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Sub Total Price:</Typography>
                <Typography variant="body2" sx={{ color: colors.textSecondary }}>{orderDetails.subTotalItemPrice}</Typography>
              </Box> */}
              <Box sx={{ mb: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Delivery Expected Date:</Typography>
                <Typography variant="body2" sx={{ color: colors.textSecondary }}>{orderDetails.deliveryOptions[0]}</Typography>
              </Box>
              <Box sx={{ mb: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Delivery Charges:</Typography>
                <Typography variant="body2" sx={{ color: colors.textSecondary }}>${orderDetails.deliveryOptions[2]}</Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: colors.primary }}>Total</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: colors.primary }}>${orderDetails.totalPayment}</Typography>
              </Box>
            </Box>
            <PrimaryButton myStyle={{ mt: 1 }} onClick={() => {
              navigate('/order-details')
            }} fullWidth startIcon={<ArrowBack />}>
              Go Back
            </PrimaryButton>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default ViewOrderPage