import React from 'react';
import { Container, Typography, Grid, Box, AccordionSummary, AccordionDetails } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import { Add } from '@mui/icons-material';
import { styled } from '@mui/system';
import { colors } from 'style/colors';



const Accordion = styled((props) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

function Faqs() {
  return (
    <Box sx={{ py: 7 }}>
      <Container>
        <Typography variant="body1" sx={{ color: '#919EAB', fontWeight: 600 }} >FAQS</Typography>
        <Box>
          <Typography variant="h4" sx={{ mt: 1.5, mb: 3.5, fontWeight: 700, color: colors.black }} >Frequently Asked Questions</Typography>
          <Accordion>
            <AccordionSummary expandIcon={<Add sx={{ color: colors.primary }} />}>
              <Typography><span style={{ color: colors.primary, fontWeight: "bold", marginRight: 6 }}>Q:</span>How much does a custom t-shirt cost?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Thanks to Custom Ink’s all-inclusive pricing, you’ll know your price up front based off of three things: the type of t-shirt you select, the number of ink colors in your design, and the total number of shirts in your order. The more custom tees you order, the lower your price will be per shirt! Other factors, such as adding personalization, can change your price, but our sales team is ready to help you through every step of the ordering process.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<Add sx={{ color: colors.primary }} />}>
              <Typography><span style={{ color: colors.primary, fontWeight: "bold", marginRight: 6 }}>Q:</span>How do I design my own custom t-shirt?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Custom Ink’s best-in-class Design Lab makes it fun and easy to create a great custom t-shirt design. Our lab offers tons of great clip art and fonts, and you can easily upload a logo to your t-shirt. Plus, no matter where you are in the design process, whether just starting out or in need of a second opinion, our experts are ready to help seven days a week. Give us a call at 1-800-293-4232 so that we can help your great idea turn into a great custom t-shirt design.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<Add sx={{ color: colors.primary }} />}>
              <Typography><span style={{ color: colors.primary, fontWeight: "bold", marginRight: 6 }}>Q:</span>Can I order just one custom t-shirt?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                No matter how many shirts you need, one custom t-shirt for a gift or one thousand shirts to outfit the whole company, Custom Ink is ready to help. We proudly offer tons of t-shirt options, from shirts with no minimum to long sleeve t-shirts, to performance tees, to raglans.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<Add sx={{ color: colors.primary }} />}>
              <Typography><span style={{ color: colors.primary, fontWeight: "bold", marginRight: 6 }}>Q:</span>How do I order custom t-shirts for my group?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Here at Custom Ink, we wanted to make ordering shirts for a group as easy as possible, so we created the Group Order Form. Collecting sizes and payment doesn’t have to be hard! Simply save your design, use the “Your Account” tab in the top right hand corner of your browser, find the design you want to use, and choose the option “Set Up a Group Order Form.”
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<Add sx={{ color: colors.primary }} />}>
              <Typography><span style={{ color: colors.primary, fontWeight: "bold", marginRight: 6 }}>Q:</span>Can I order different t-shirt colors with the same design?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Of course! Once you’ve finalized your t-shirt design, you can add additional shirt colors right from the Design Lab. Select the “Add Products” icon on the bottom left-hand side of the lab and a new window will appear. The first tile will show your current t-shirt and allow you to change colors easily. You can also browse complementary styles or select something else from our catalog.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
}

export default Faqs;