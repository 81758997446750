import React from 'react'
import { Box, } from '@mui/material';
import Marquee from "react-fast-marquee";

import { colors } from 'style/colors'

function NoticeHeader() {
  return (
    <Box sx={{ bgcolor: colors.primary, textAlign: "center", py: 1.5 }} >
      <Marquee speed={70} gradient={false} style={{ color: colors.white, fontWeight: "bold", fontSize: "18px" }}>
        15% off exclusively on all T-Shirts
      </Marquee>
    </Box>
  )
}

export default NoticeHeader