import React from 'react';

//  *Import Components
import MensCollections from './Shared/MensCollections';
import WomensCollections from './Shared/WomensCollections';
import KidsCollections from './Shared/KidsCollections';
import HomeCarousel from 'component/HomeCarousel/HomeCarousel';
import Layout from 'layout/Layout';
import { Grid, Typography, Divider, Box, Container } from '@mui/material';
import { colors } from './../style/colors';
import { Images } from './../assets/Images';
import { PrimaryButton } from 'component/UI/Button';
import { ArrowForward } from '@mui/icons-material';
import Apparel from './Shared/Apparel';
import CTA from './Shared/CTA';

function Home() {
  return (
    <Layout>
      <HomeCarousel />
      {/* Men's Collections */}
      {/* <MensCollections /> */}
      {/* Women's Collections */}
      {/* <WomensCollections /> */}
      {/* Kid's Collections */}
      {/* <KidsCollections /> */}
      <Container sx={{ my: 4 }}>
        <CTA />
        {/* Apparel */}
        <Apparel />
        {/* HOW TO MAKE CUSTOM T-SHIRTS */}
        <Grid container alignItems="center" sx={{ my: 6, boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;`, borderRadius: "8px", borderTop: `7px solid ${colors.primary}` }}>
          <Grid item xs={12} md={12} textAlign={'center'}>
            <Typography
              variant="h4"
              sx={{ color: colors.black, mt: 4, textAlign: "center", fontWeight: 600, }}>How To Make Custom T-Shirts</Typography>
            <Divider sx={{ bgcolor: colors.primary, width: '140px', padding: '1px', margin: 'auto', fontSize: '32px', mb: 2 }} />
            <Typography variant='body2' sx={{ color: colors.textSecondary, mb: 2 }}>Four simple steps you need to make the perfect custom t-shirts.</Typography>
          </Grid>
          <Divider sx={{ width: "100%", bgcolor: '#00000000', my: 2 }} />
          <Grid item xs={12} md={3}>
            <Box sx={{ p: 2, textAlign: "center", cursor: "pointer", borderRight: `1px solid ${colors.gunMetal}`, height: "220px" }}>
              <img width="30%" src={Images.chooseProduct} alt="Choose Product" />
              <Typography sx={{ fontSize: "18px", color: colors.primary, fontWeight: 500, my: 1 }}>Choose a Product</Typography>
              <Typography variant='body2' sx={{ color: colors.textSecondary, px: 2, textAlign: "left" }}>Get started by choosing the perfect product. From our basic tee to our premium super soft, we've got it all.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ p: 2, textAlign: "center", cursor: "pointer", borderRight: `1px solid ${colors.gunMetal}`, height: "220px" }}>
              <img width="30%" src={Images.customizeProduct} alt="Customize Product" />
              <Typography sx={{ fontSize: "18px", color: colors.primary, fontWeight: 500, my: 1 }}>Customize</Typography>
              <Typography variant='body2' sx={{ color: colors.textSecondary, px: 2, textAlign: "left" }}>Customize your t-shirt with thousands of professionally designed clip art and design templates or upload your own images.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ p: 2, textAlign: "center", cursor: "pointer", borderRight: `1px solid ${colors.gunMetal}`, height: "220px" }}>
              <img width="30%" src={Images.checkoutProduct} alt="Checkout Product" />
              <Typography sx={{ fontSize: "18px", color: colors.primary, fontWeight: 500, my: 1 }}>Checkout</Typography>
              <Typography variant='body2' sx={{ color: colors.textSecondary, px: 2, textAlign: "left" }}>Order with the confidence that your custom t-shirts will be exactly the way you want them. Guaranteed.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ p: 2, textAlign: "center", cursor: "pointer", height: "220px" }}>
              <img width="30%" src={Images.relax} alt="Relax" />
              <Typography sx={{ fontSize: "18px", color: colors.primary, fontWeight: 500, my: 1 }}>Relax</Typography>
              <Typography variant='body2' sx={{ color: colors.textSecondary, px: 2, textAlign: "left" }}>We'll take care of the rest. Your t-shirts will arrive at your door in just a few days.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={{ textAlign: "center", my: 4 }}>
              <PrimaryButton endIcon={<ArrowForward />} >Start Designing</PrimaryButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout >
  )
}

export default Home