// *Server Side
let phpServer = 'https://api-designtool.optech.pk/';

// *Client Side
let reactServer = 'https://design-tool.mangotechsoftwares.com/';

export const imageURL = () => {
    let url = phpServer;
    return url;
};

const fontURL = {
    url: reactServer + '/fonts/',
};

export default fontURL;
