import React, { useState, useEffect, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, Button, StepLabel, Step, Stepper, Box, Divider, TextField, FormControl, Grid, FormGroup, Checkbox, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { colors } from 'style/colors';
import Layout from './../../layout/Layout';
import { LoadingBtn, PrimaryButton } from 'component/UI/Button';
import { SecondaryButton } from './../../component/UI/Button';
import { Error } from 'component/UI/Error';
import { selectDeliveryDetails, selectOrderSaveDetails, selectTotalDetails, selectTotalAmountToPay, selectTotalYouthSizes, selectTotalAdultSizes, setTotalAddToCart, setTotalAddToCartItemsAfterRemove } from 'features/counter/counterSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Service } from 'config/service';
import { useDispatch } from 'react-redux';

const steps = ['Billing Info', 'Shipping Info', 'Payment'];

function Payment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [sameAddress, setSameAddress] = useState(false);
  const [billingAddress, setBillingAddress] = useState('')
  const [shippingAddress, setShippingAddress] = useState('')
  const [paymentStatus, setPaymentStatus] = useState(false)

  // Using Redux
  const { totalItems, eachItemPrice } = useSelector(selectTotalDetails);
  const { deliveryDays, deliveryFeeToPay } = useSelector(selectDeliveryDetails);
  const orderSaveDetails = useSelector(selectOrderSaveDetails);
  const totalAmountToPay = useSelector(selectTotalAmountToPay);
  const totalAdultSizes = useSelector(selectTotalAdultSizes);
  const totalYouthSizes = useSelector(selectTotalYouthSizes);


  var designSizes = []
  console.log("🚀 ~ file: Payment.js ~ line 35 ~ useEffect ~ designSizes", designSizes)
  useEffect(() => {
    for (const key in totalAdultSizes) {
      if (Object.hasOwnProperty.call(totalAdultSizes, key)) {
        const element = totalAdultSizes[key];
        if (element !== 0) {
          let obj = { size: key, quantity: +element }
          designSizes.push(obj);
        }
      }
    }
    for (const key in totalYouthSizes) {
      if (Object.hasOwnProperty.call(totalYouthSizes, key)) {
        const element = totalYouthSizes[key];
        if (element !== 0) {
          let obj = { size: key, quantity: +element }
          designSizes.push(obj);
        }
      }
    }

  }, [])


  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (sameAddress) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (sameAddress) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const paymentContactInfo = async (formData) => {
    console.log("paymentContactInfo", formData)
    setBillingAddress(formData.address + ',' + formData.state + ',' + formData.city + ',' + formData.postalCode + ',' + formData.country)
    console.log("🚀 ~ file: Payment.js ~ line 60 ~ paymentContactInfo ~ billingAddress", billingAddress)
    handleNext()
  }

  const paymentShippingInfo = async (formData) => {
    console.log("paymentShippingInfo", formData)
    setShippingAddress(formData.ship_address + ',' + formData.ship_state + ',' + formData.ship_city + ',' + formData.ship_postalCode + ',' + formData.ship_country)
    console.log("🚀 ~ file: Payment.js ~ line 67 ~ paymentShippingInfo ~ shippingAddress", shippingAddress)
    handleNext()
  }

  const handleShowPaymentDialog = async () => {
    try {
      setPaymentStatus(true)
      let formData = new FormData();
      formData.append('designName', orderSaveDetails.name);
      formData.append('isDefaultProduct', 1);
      formData.append('productId', 0);
      formData.append('productColor', orderSaveDetails.maskColor);
      formData.append('designArtWork', JSON.stringify(orderSaveDetails.designArtWork));
      formData.append('designText', JSON.stringify(orderSaveDetails.designText));
      formData.append('designSize', designSizes);
      orderSaveDetails.designUploadWork.forEach((element, index) => {
        formData.append(`designUploadWork[${index}][angle]`, element.angle);
        formData.append(`designUploadWork[${index}][image]`, element.image);
        formData.append(`designUploadWork[${index}][top]`, element.top);
        formData.append(`designUploadWork[${index}][left]`, element.left);
        formData.append(`designUploadWork[${index}][rotation]`, element.rotation);
        formData.append(`designUploadWork[${index}][flip_x]`, element.flip_x);
        formData.append(`designUploadWork[${index}][flip_y]`, element.flip_y);
        formData.append(`designUploadWork[${index}][px_to_inch_width]`, element.px_to_inch_width);
        formData.append(`designUploadWork[${index}][px_to_inch_height]`, element.px_to_inch_height);
        formData.append(`designUploadWork[${index}][width]`, element.width);
        formData.append(`designUploadWork[${index}][height]`, element.height);
        formData.append(`designUploadWork[${index}][image_color]`, element.image_color);
        formData.append(`designUploadWork[${index}][is_make_one_color]`, element.is_make_one_color);
        formData.append(`designUploadWork[${index}][is_background_remove]`, element.is_background_remove);
        formData.append(`designUploadWork[${index}][layer_sequence]`, element.layer_sequence);
      });
      formData.append('totalQuantity', totalItems);
      if (sameAddress) {
        formData.append('shippingAddress', billingAddress);
      }
      else {
        formData.append('shippingAddress', shippingAddress);
      }

      formData.append('billingAddress', billingAddress);
      formData.append('eachProductPrice', eachItemPrice);
      formData.append('shippingCharges', deliveryFeeToPay);
      formData.append('totalAmount', totalAmountToPay);
      console.log('file: design-tool.js => line 939 => saveAndShareDesign => formData', formData);
      const { status } = await Service.orderDesignDetails(formData);
      setPaymentStatus(false);
      if (status) {
        dispatch(setTotalAddToCartItemsAfterRemove([]));
        Swal.fire({
          title: 'Thank you for the order!',
          // text: 'Your Order is under process',
          icon: 'success',
        }).then(() => {
          // dispatch(setTotalAddToCartItemsAfterRemove([]));
          navigate('/')
        });
      }
    } catch (error) {
      console.log("🚀 ~ file: Payment.js ~ line 105 ~ handleShowPaymentDialog ~ error", error)
    }
  }

  return (
    <Layout>
      <Box sx={{ display: "flex", backgroundColor: colors.whiteSpace, p: 4, justifyContent: "space-between" }}>
        <Box sx={{ width: '100%', p: 6, backgroundColor: colors.white, borderRadius: "12px", mr: 4 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Fragment>
            {/* <Typography component={"form"} onSubmit={handleSubmit(paymentInfo)}> */}
            {activeStep === 0 &&
              <Box>
                <Typography component={"form"} onSubmit={handleSubmit(paymentContactInfo)}>
                  <Grid container spacing={2}>
                    {/* First Name */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="First name"
                          type="text"
                          error={errors?.first_name?.message && (true)}
                          {...register("first_name", {
                            required: 'Please enter your first name.',
                          })}
                        />
                        {errors?.first_name?.message && (
                          <Error message={errors?.first_name?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* Last Name */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Last name"
                          type="text"
                          error={errors?.last_name?.message && (true)}
                          {...register("last_name", {
                            required: 'Please enter your last name.',
                          })}
                        />
                        {errors?.last_name?.message && (
                          <Error message={errors?.last_name?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* Email */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Email"
                          type="email"
                          error={errors?.email?.message && (true)}
                          {...register("email", {
                            required: 'Please enter your email.',
                          })}
                        />
                        {errors?.email?.message && (
                          <Error message={errors?.email?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* Telephone */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Phone"
                          type="number"
                          error={errors?.phone?.message && (true)}
                          {...register("phone", {
                            required: 'Please enter your phone.',
                          })}
                        />
                        {errors?.phone?.message && (
                          <Error message={errors?.phone?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* Address */}
                    <Grid item xs={12} md={12}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Address"
                          type="text"
                          multiline
                          error={errors?.address?.message && (true)}
                          {...register("address", {
                            required: 'Please enter your address.',
                          })}
                        />
                        {errors?.address?.message && (
                          <Error message={errors?.address?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* Country */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Country"
                          type="text"
                          error={errors?.country?.message && (true)}
                          {...register("country", {
                            required: 'Please enter your country.',
                          })}
                        />
                        {errors?.country?.message && (
                          <Error message={errors?.country?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* City */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="City"
                          type="text"
                          error={errors?.city?.message && (true)}
                          {...register("city", {
                            required: 'Please enter your city.',
                          })}
                        />
                        {errors?.city?.message && (
                          <Error message={errors?.city?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* State */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="State"
                          type="text"
                          error={errors?.state?.message && (true)}
                          {...register("state", {
                            required: 'Please enter your state.',
                          })}
                        />
                        {errors?.state?.message && (
                          <Error message={errors?.state?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* Postal Code */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Postal Code"
                          type="number"
                          error={errors?.postalCode?.message && (true)}
                          {...register("postalCode", {
                            required: 'Please enter your postalCode.',
                          })}
                        />
                        {errors?.postalCode?.message && (
                          <Error message={errors?.postalCode?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox checked={sameAddress} onClick={() => { setSameAddress(!sameAddress) }} />} label="Billing Address same as Shipping Address?" />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <SecondaryButton
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      myStyle={{ mr: 1 }}
                    >
                      Back
                    </SecondaryButton>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <PrimaryButton type="submit"
                    >
                      Next
                    </PrimaryButton>
                  </Box>
                </Typography>
              </Box>
            }
            {activeStep === 1 &&
              <Box>
                <Typography component={"form"} onSubmit={handleSubmit(paymentShippingInfo)}>
                  <Grid container spacing={2}>
                    {/* First Name */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="First name"
                          type="text"
                          error={errors?.ship_first_name?.message && (true)}
                          {...register("ship_first_name", {
                            required: 'Please enter your first name.',
                          })}
                        />
                        {errors?.ship_first_name?.message && (
                          <Error message={errors?.ship_first_name?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* Last Name */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Last name"
                          type="text"
                          error={errors?.ship_last_name?.message && (true)}
                          {...register("ship_last_name", {
                            required: 'Please enter your last name.',
                          })}
                        />
                        {errors?.ship_last_name?.message && (
                          <Error message={errors?.ship_last_name?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* Email */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Email"
                          type="email"
                          error={errors?.ship_email?.message && (true)}
                          {...register("ship_email", {
                            required: 'Please enter your email.',
                          })}
                        />
                        {errors?.ship_email?.message && (
                          <Error message={errors?.ship_email?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* Telephone */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Phone"
                          type="number"
                          error={errors?.ship_phone?.message && (true)}
                          {...register("ship_phone", {
                            required: 'Please enter your phone.',
                          })}
                        />
                        {errors?.ship_phone?.message && (
                          <Error message={errors?.ship_phone?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* Address */}
                    <Grid item xs={12} md={12}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Address"
                          type="text"
                          multiline
                          error={errors?.ship_address?.message && (true)}
                          {...register("ship_address", {
                            required: 'Please enter your shipping address.',
                          })}
                        />
                        {errors?.ship_address?.message && (
                          <Error message={errors?.ship_address?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* Country */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Country"
                          type="text"
                          error={errors?.ship_country?.message && (true)}
                          {...register("ship_country", {
                            required: 'Please enter your country.',
                          })}
                        />
                        {errors?.ship_country?.message && (
                          <Error message={errors?.ship_country?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* City */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="City"
                          type="text"
                          error={errors?.ship_city?.message && (true)}
                          {...register("ship_city", {
                            required: 'Please enter your city.',
                          })}
                        />
                        {errors?.ship_city?.message && (
                          <Error message={errors?.ship_city?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* State */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="State"
                          type="text"
                          error={errors?.ship_state?.message && (true)}
                          {...register("ship_state", {
                            required: 'Please enter your state.',
                          })}
                        />
                        {errors?.ship_state?.message && (
                          <Error message={errors?.ship_state?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    {/* Postal Code */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="Postal Code"
                          type="number"
                          error={errors?.ship_postalCode?.message && (true)}
                          {...register("ship_postalCode", {
                            required: 'Please enter your postalCode.',
                          })}
                        />
                        {errors?.ship_postalCode?.message && (
                          <Error message={errors?.ship_postalCode?.message} />
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <SecondaryButton
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      myStyle={{ mr: 1 }}
                    >
                      Back
                    </SecondaryButton>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <PrimaryButton type="submit">
                      Next
                    </PrimaryButton>
                  </Box>
                </Typography>
              </Box>
            }
            {activeStep === 2 &&
              <Box sx={{ py: 4, px: 2 }}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="COD"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value="COD" control={<Radio />} label="Cash on Delivery" />
                  </RadioGroup>
                </FormControl>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <SecondaryButton
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    myStyle={{ mr: 1 }}
                  >
                    Back
                  </SecondaryButton>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <LoadingBtn loading={paymentStatus} onClick={() => { handleShowPaymentDialog() }} >
                    Pay
                  </LoadingBtn>
                </Box>
              </Box>
            }
          </Fragment>
        </Box>
        <Box sx={{ width: "420px", p: 4, backgroundColor: colors.white, borderRadius: "12px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "22px", color: colors.black }}>Order Summary</Typography>
          <Divider sx={{ my: 2 }} />
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
              <Typography sx={{ fontSize: "14px", color: colors.black }}>Subtotal ({totalItems} items)</Typography>
              <Typography sx={{ fontSize: "14px", color: colors.black }}>${totalAmountToPay}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
              <Typography sx={{ fontSize: "14px", color: colors.black }}>{deliveryDays}</Typography>
              <Typography sx={{ fontSize: "14px", color: colors.black }}>${deliveryFeeToPay}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
              <Typography sx={{ fontSize: "14px", color: colors.black }}>Tax (To be Calculated)</Typography>
              <Typography sx={{ fontSize: "14px", color: colors.black }}>--</Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", my: 2 }}>
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>Total</Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>${totalAmountToPay}</Typography>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default Payment