import React, { Fragment } from 'react'

// *Import Components

import { Divider } from '@mui/material'
import Footer from 'component/Footer/Footer'
import Header from 'component/Header/Header'

function Layout({ children }) {
  return (
    <Fragment>

      {/* ========== Header ========== */}
      < Header />

      {children}

      {/* ========== Footer ========== */}

      {/* <Divider sx={{ mt: 12 }} /> */}

      < Footer />

    </Fragment>
  )
}

export default Layout