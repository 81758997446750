export const errorHandler = (error) => {
  console.log("🚀 ~ file: errorHandler.js ~ line 10 ~ errorHandler ~ error", error)
  if (error.response) {
    return error.response.data.error_description;
  } else if (error.request) {
    return error.error;
  } else {
    return 'Something went wrong';
  }
};
