import { Dialog, DialogContent, TextField, Typography, Box } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { colors } from 'style/colors';
import { setAddDesignEmail, setAddDesignName, } from "features/counter/counterSlice";
import { useDispatch } from "react-redux";
import { Close } from '@mui/icons-material';
import { LoadingBtn } from 'component/UI/Button';


function SaveAndShare({ isSaveAndShareDialog, saveAndShareDesignDialog, saveAndShareDialogFormRef, saveAndShareDesign, addToCartStatus, loadingStatus }) {
  // Using Redux
  const dispatch = useDispatch();

  const [value, setValue] = useState({
    name: '',
    email: '',
  })

  // *set save and share dialog value
  const setSaveAndShareValue = (prop) => (event) => {
    setValue({ ...value, [prop]: event.target.value });
  };

  return (
    <Fragment>
      <Dialog sx={{
        "& .MuiDialog-paper": {
          borderRadius: "18px",
        }
      }} open={isSaveAndShareDialog} onClose={() => { saveAndShareDesignDialog(); setValue({ name: '', email: '' }) }} >
        <form ref={saveAndShareDialogFormRef}>
          <DialogContent sx={{ position: "relative", }}>
            <Box sx={{ textAlign: "center", my: 2 }}>
              <Typography style={{ fontSize: "23px", fontWeight: 500 }}>Save And Share Your Design</Typography>
              <Typography style={{ my: 1, color: colors.textSecondary, fontSize: "16px" }}>
                View it anywhere, and share it with others!
              </Typography>
            </Box>
            <Box sx={{ mx: 2, my: 2 }}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Design Name"
                type="text"
                value={value.name}
                onChange={setSaveAndShareValue('name')}
                fullWidth
                variant="outlined"
                required
              />
              <TextField
                margin="dense"
                id="name"
                label="Email"
                type="email"
                value={value.email}
                onChange={setSaveAndShareValue('email')}
                fullWidth
                variant="outlined"
                required
              />
              <LoadingBtn loading={loadingStatus} myStyle={{ my: 2, float: "right" }} onClick={() => { dispatch(setAddDesignName(value.name)); dispatch(setAddDesignEmail(value.email)); saveAndShareDesign(value.name, value.email) }}>Save</LoadingBtn>
            </Box>
          </DialogContent>
          <Close onClick={() => { saveAndShareDesignDialog(); setValue({ name: '', email: '' }) }} sx={{ position: "absolute", top: 15, right: 15, cursor: "pointer" }} />
          {/* <DialogActions sx={{ mb: 2 }}>
            <Button onClick={saveAndShareDesignDialog}>Cancel</Button>
          </DialogActions> */}
        </form>
      </Dialog>
    </Fragment>
  )
}

export default SaveAndShare