import React, { useState } from 'react'
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Images from 'assets/Images';

// Color Box Select Styling
const useStyles = makeStyles({
  colorBox: {
    width: "30px",
    height: "30px",
    boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;',
    borderRadius: "5px",
    margin: "0px 10px"
  },
  selectedColorBox: {
    width: "30px",
    height: "30px",
    boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;',
    borderRadius: "5px",
    margin: "5px",
    cursor: "pointer"
  },
  tick: {
    position: "absolute",
    cursor: 'pointer',
    top: 5, left: 5,
    color: "white",
    width: "30px"
  }
})

// Color List
const colorsList = ["#000", "#fff", "#DFFF00", "#FFBF00", "#DE3163", "#6495ED", "#CD5C5C"]

function ColorBox() {
  // Color Platte
  const classes = useStyles()
  const [selectedColor, setSelectedColor] = useState("#000");
  return (
    <Box sx={{ display: "flex", alignItems: "center", flexWrap: 'wrap', my: 2 }}>
      {colorsList.map((item, index) => (
        <Box sx={{ position: "relative" }} key={index}>
          <Box className={classes.selectedColorBox} sx={{ bgcolor: `${item}` }} onClick={() => {
            setSelectedColor(`${item}`)
          }}></Box>
          {item === selectedColor && <img src={Images.Tick} alt="" className={classes.tick} />}
        </Box>
      ))}
    </Box>
  )
}

export default ColorBox