import React, { Fragment, useState, useEffect } from 'react';
import { Box, Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { GppBadTwoTone, RemoveRedEye, VerifiedUserTwoTone } from '@mui/icons-material';

//* Import Component
// import { Service } from 'app/config/service';
import Lottie from 'react-lottie';
import animationData from '../../assets/images/loader';
import useAuth from './../../hooks/useAuth';
import Layout from 'layout/Layout';
import { colors } from 'style/colors';
import ProfileDetail from './../../component/ProfileDetails/ProfileDetails';
import ViewOrder from './../../component/ViewOrder/ViewOrder';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.primary,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover,

  // },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

function MyOrders() {

  const { user, logout } = useAuth();
  // const userId = user.split("_")[0]
  const [itemID, setItemID] = useState('')
  const navigate = useNavigate();

  const [openOrder, setOpenOrder] = useState(false);
  const [loader, setLoader] = useState(true);
  const handleOrderDialog = () => {
    setOpenOrder(!openOrder);
  };

  const orderDetails = [
    { Id: 1002, DesignName: 'Custom Design', AddOn: '22-Sep, 2022', Status: "Verified", PaymentStatus: "Paid", TotalPayment: 200 },
    { Id: 1003, DesignName: 'T-Shirt Design', AddOn: '23-Sep, 2022', Status: "Verified", PaymentStatus: "Unpaid", TotalPayment: 300 },
    { Id: 1004, DesignName: 'Cap Design', AddOn: '24-Sep, 2022', Status: "Not Verified", PaymentStatus: "Paid", TotalPayment: 400 },
    { Id: 1005, DesignName: 'Hoodie Design', AddOn: '24-Sep, 2022', Status: "Not Verified", PaymentStatus: "Unpaid", TotalPayment: 600 },
    { Id: 1006, DesignName: 'Sweater Design', AddOn: '25-Sep, 2022', Status: "Not Verified", PaymentStatus: "Paid", TotalPayment: 450 },
    { Id: 1007, DesignName: 'My Design', AddOn: '26-Sep, 2022', Status: "Verified", PaymentStatus: "Unpaid", TotalPayment: 320 },
    { Id: 1008, DesignName: 'Shirt Design', AddOn: '27-Sep, 2022', Status: "Not Verified", PaymentStatus: "Paid", TotalPayment: 700 },
    { Id: 1009, DesignName: 'Johns Design', AddOn: '27-Sep, 2022', Status: "Not Verified", PaymentStatus: "Paid", TotalPayment: 700 },
  ]

  // For Api Call
  // const [orderDetails, setOrderDetails] = useState([]);

  // //* Get Order Details API
  // const getOrderDetails = async () => {
  //   try {
  //     const { Data } = await Service.getOrderDetails()
  //     console.log('file: MyOrders.js => line 69 => getOrderDetails => Data', Data);
  //     setOrderDetails(Data)
  //     setLoader(false)
  //   } catch (error) {
  //     console.log('file: MyOrders.js => line 69 => getOrderDetails => error', error)

  //   }
  // }

  // useEffect(() => {
  //   getOrderDetails()
  // }, [])

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 1000)
  }, [])


  return (
    <Layout>
      {/* ====== View Order dialog ===== */}
      {/* <ViewOrder id={itemID} open={openOrder} handleClose={handleOrderDialog} /> */}
      <Grid container sx={{ mt: 4, justifyContent: 'center' }}>

        <Grid item xs={12} md={10}>
          <ProfileDetail id={1} pageName={"Order Details"} />

          {loader ? (
            <Box sx={{ height: "500px", display: "flex", alignItems: "center", }}>
              <Lottie
                options={defaultOptions}
                height={300}
                width={300}
              />
            </Box>) : (
            // Table
            <TableContainer component={Paper} sx={{
              cursor: 'pointer', maxHeight: 400, my: 4,
              '&::-webkit-scrollbar': {
                bgcolor: colors.white,
                height: "8px",
                borderRadius: "10px"
              },
              '&::-webkit-scrollbar-thumb': {
                bgcolor: colors.primary,
                borderRadius: "10px",
                cursor: "pointer"
              },
            }}>
              <Table sx={{ minWidth: 1100, minHeight: 200 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell><Typography variant="body2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Order ID(#)</Typography></StyledTableCell>
                    <StyledTableCell><Typography variant="body2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Design Name</Typography></StyledTableCell>
                    <StyledTableCell ><Typography variant="body2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Order Date</Typography></StyledTableCell>
                    <StyledTableCell ><Typography variant="body2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Status</Typography></StyledTableCell>
                    <StyledTableCell ><Typography variant="body2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Payment</Typography></StyledTableCell>
                    <StyledTableCell ><Typography variant="body2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Total&nbsp;($)</Typography></StyledTableCell>
                    <StyledTableCell ><Typography variant="body2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Action</Typography></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderDetails.map((item, index) => (
                    <Fragment>
                      <StyledTableRow
                        key={index}>
                        <StyledTableCell>
                          <Typography variant="body2" sx={{ textAlign: 'center' }}>{item.Id}</Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant="body2" sx={{ textAlign: 'center' }}>{item.DesignName}</Typography>
                        </StyledTableCell>
                        <StyledTableCell ><Typography variant="body2" sx={{ textAlign: 'center' }}>{item.AddOn}</Typography></StyledTableCell>
                        <StyledTableCell >
                          {item.Status === "Verified" ? (
                            <VerifiedUserTwoTone sx={{ color: 'green' }} />
                          ) : (
                            <GppBadTwoTone sx={{ color: 'red', fontSize: '28px' }} />
                          )}
                        </StyledTableCell>
                        <StyledTableCell >
                          <Chip label={item.PaymentStatus} sx={{
                            backgroundColor: item.PaymentStatus === "Paid" ? "#E6F6E9" : "#F3E1E2",
                            color: item.PaymentStatus === "Paid" ? "#59C36A" : "#E26767", width: "80px", height: '30px', fontWeight: "bold"
                          }} variant="contained" />
                        </StyledTableCell>
                        <StyledTableCell ><Typography variant="body2" sx={{ textAlign: 'center' }}>${item.TotalPayment}</Typography></StyledTableCell>
                        <StyledTableCell >
                          <Tooltip title="View Details" >
                            {/* <IconButton onClick={() => { setItemID(item.Id); setOpenOrder(!openOrder) }}> */}
                            <IconButton onClick={() => { setItemID(item.Id); navigate(`/order-invoice/${item.Id}`) }}>
                              <RemoveRedEye color="primary" sx={{ fontSize: "22px" }} />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default MyOrders