import { StarRate } from '@mui/icons-material'
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material'
import { colors } from 'style/colors'
import Images, { BasicPlan, Brand1, Brand2, Brand3, Brand4, Brand5, Brand6, Brand7, Brand8 } from 'assets/Images'
import Breadcrumb from 'component/Breadcrumb/Breadcrumb'
import ColorBox from 'component/UI/ColorBox'
import Layout from 'layout/Layout'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import ProductSlider from './Shared/ProductSlider'


const features = [
  "4 oz., 100% moisture-wicking polyester",
  "Odor resistant",
  "Stain resistant",
  "Snag resistant",
  "Women's complement is A4 Women's Promotional Performance Shirt",
]

const juniorSizes = [
  'YS',
  'YM',
  'YL',
  'YXL',
]

const adultSizes = [
  'S',
  'M',
  'L',
  'XL',
  '2XL',
  '3XL',
]

const brandSVG = [
  <Brand1 />, <Brand2 />, <Brand3 />, <Brand4 />, <Brand5 />, <Brand6 />, <Brand7 />, <Brand8 />,
  <Brand1 />, <Brand2 />, <Brand3 />, <Brand4 />, <Brand5 />, <Brand6 />, <Brand7 />, <Brand8 />,
]
function StartDesigning() {
  const { pathname } = useLocation();
  return (
    <Layout>
      <Container>
        <Box sx={{ p: 2, my: 3, border: `2px solid ${colors.black}`, borderRadius: 2 }}>
          {/* Breadcrumbs */}
          <Breadcrumb path={pathname} />
        </Box>
        <Grid container>
          <Grid item xs={12} md={12} container sx={{ p: 3, mb: 4, boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px;` }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" sx={{ fontWeight: "bold", color: colors.black }}>A4 Promotional Performance Shirt</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {[...Array(Number(5))].map((item, index) => (
                  <StarRate sx={{ color: "#f9a60f" }} />
                ))}
                <Typography sx={{ mx: 1, color: colors.darkGray }}>(2,090)</Typography>
              </Box>
              <Typography sx={{ color: colors.black, fontSize: "18px", my: 1 }}>Elevate your group's comfort in performance wear while staying on budget! Keeping you cool is the name of the game in this shirt that is priced to win ‑ now available in a women's complement!</Typography>
              <Button variant='contained' component={Link} to="/design-tool" sx={{
                my: 1.5, width: "100%", fontWeight: "bold", fontSize: "16px", backgroundColor: colors.primary,
                "&:hover": {
                  backgroundColor: "#ff770d",
                }
              }} >Design Lab</Button>
              <Box>
                <Typography sx={{ fontSize: "20px", color: colors.black, fontWeight: "bold", mt: 2 }}>Colors:</Typography>
                <ColorBox />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "20px", color: colors.black, fontWeight: "bold" }}>Sizes Available in:</Typography>
                {/* Youth Size */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {juniorSizes.map((item, index) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography key={index} sx={{ fontSize: "16px", color: colors.black }}>{item}</Typography>
                      <Divider orientation='vertical' sx={{
                        color: colors.darkGray, height: "24px", mx: 1.5
                      }} />
                    </Box>
                  ))}
                </Box>
                {/* Adult Size */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {adultSizes.map((item, index) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography key={index} sx={{ fontSize: "16px", color: colors.black }}>{item}</Typography>
                      <Divider orientation='vertical' sx={{
                        color: colors.darkGray, height: "24px", mx: 1.5
                      }} />
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "20px", color: colors.black, fontWeight: "bold" }}>Fit & Sizing:</Typography>
                <Typography sx={{ fontSize: "18px", color: colors.black }}>Relaxed fit: loose fit on body, chest, & arms</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 3, height: "550px" }}>
                <ProductSlider />
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ p: 3, boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px;` }}>
            <Grid item xs={12} md={12}>
              <Typography variant="h4" sx={{ color: colors.black }}>Product Overview</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h6' fontWeight={'bold'} color={colors.black}>Features</Typography>
              <Box>
                <Divider sx={{ color: colors.darkGray, width: '100%' }} />
                <ul style={{ padding: 4, marginLeft: 14, fontFamily: "sans-serif" }}>
                  {features.map((item, index) => (
                    <li key={index} style={{ lineHeight: 1.75, fontSize: "16px", color: colors.black }}>{item}</li>
                  ))}
                </ul>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant='h6' fontWeight={'bold'} color={colors.black} my={2}>Additional Details</Typography>
              <Box>
                <Divider sx={{ color: colors.darkGray, width: '100%' }} />
                <Typography variant='h6' fontWeight={'bold'} color={colors.black} mt={2} >Delivery Options</Typography>
                <ul style={{ padding: 4, marginLeft: "30px", fontFamily: "sans-serif" }}>
                  <li style={{ lineHeight: 1.75, fontSize: "16px", color: colors.black }}>
                    <span style={{ fontWeight: "bold", marginRight: 4 }}>Free</span>Delivery — Get it by Fri. Aug 19
                  </li>
                  <li style={{ lineHeight: 1.75, fontSize: "16px", color: colors.black }}>Rush or Super Rush — Get it as soon as Wed. Aug 10</li>
                  <li style={{ lineHeight: 1.75, fontSize: "16px", color: colors.black }}>
                    <span style={{ color: colors.primary, fontWeight: "bold", marginRight: 4 }}>SHIP TO MULTIPLE ADDRESSES</span>— Flat rate shipping is $6.95 per US address
                  </li>
                </ul>
                <Typography variant='h6' fontWeight={'bold'} color={colors.black} >Decoration</Typography>
                <Typography color={colors.black} >Screen Printing</Typography>
                <Typography variant='h6' fontWeight={'bold'} color={colors.black} >Minimum Quantity</Typography>
                <Typography color={colors.black} >6</Typography>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ p: 3, width: "100%", boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px;`, my: 4 }} >
            <Typography variant='h6' fontWeight={'bold'} color={colors.black}>Product Information</Typography>
            <Typography sx={{ fontWeight: "18px", color: colors.black, my: 2 }}>With A4 custom shirts, keeping you cool is the name of the game. Elevate your group's comfort and performance while staying under your event's budget with this great pick. A great option for sports and outdoor events, the moisture wicking fabric keeps you dry and cool. The material is stain and odor resistant to keep your group fresh, and the ultra-tight knit of the fabric resists snagging. Also, you never have to worry about these shrinking, fading, or losing their shape.</Typography>
            <Typography sx={{ fontWeight: "18px", color: colors.black, my: 2 }}>Available in almost 20 different colors, these A4 performance shirts are available for kids, teens, and adults. Plus, we carry the women's complement: our A4 Women's Promotional Performance Shirt.</Typography>
            <Typography sx={{ fontWeight: "18px", color: colors.black, my: 2 }}>The silky texture of this shirt makes it a favorite for humid climates, keeping your group cool and comfortable on the hottest of summer days. It's great for workouts and all sorts of team sports</Typography>
          </Box>
          <Box sx={{ position: "relative", p: 3, width: "100%", boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px;`, my: 4, cursor: "pointer" }} >
            <Box sx={{ p: 3, border: `1px solid ${colors.black}`, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-around" }}>
              {brandSVG.map((item, index) => (
                <Box key={index} sx={{ width: "100px", height: "100px", display: "flex", justifyContent: "center", mx: 1.5, my: 1.5 }}>
                  {item}
                </Box>
              ))}
            </Box>
            <Box sx={{ textAlign: "center", position: "absolute", top: 8, right: "40%", backgroundColor: colors.white, px: 1 }}>
              <Typography variant="h5" fontWeight={"bold"}>Shop more Brands</Typography>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export default StartDesigning