import { Box, Divider, Grid, Typography } from '@mui/material'

//* Imported Components 
import { colors } from 'style/colors'
import Images from 'assets/Images'
import React from 'react'

function KidsCollections() {
  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          color: colors.black,
          my: 4,
          textAlign: "center",
          fontWeight: 600,
        }}>
        Kid's Collections
        <Divider sx={{
          bgcolor: colors.primary,
          width: '140px',
          padding: '1px',
          margin: 'auto',
          fontSize: '32px',
          mb: 1
        }} />
      </Typography>
      <Grid container sx={{ justifyContent: "space-around", my: 4, p: 3 }}>
        <Grid item xs={12} md={3} container>
          <Grid item xs={12} md={12}>
            <Box sx={{
              width: "100%",
              height: "310px",
              backgroundSize: "cover",
              objectFit: 'contain',
              cursor: "pointer",
              position: 'relative',
              backgroundImage: `url(${Images.kidsCollection1})`,
              backgroundRepeat: "no-repeat",
              ':hover': {
                backgroundImage: `url(${Images.kidsCollection3})`,
                transition: 'all 0.3s ease-in',
              }
            }}>
              {/* <Typography sx={{ position: 'absolute', color: colors.darkGray, left: 15, top: 15, fontSize: "14px", fontWeight: "bold" }}>On Sale</Typography> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={{
              width: "100%",
              height: "310px",
              backgroundSize: "cover",
              objectFit: 'contain',
              cursor: "pointer",
              position: 'relative',
              backgroundImage: `url(${Images.kidsCollection2})`,
              backgroundRepeat: "no-repeat",
              ':hover': {
                backgroundImage: `url(${Images.kidsCollection4})`,
                transition: 'all 0.3s ease-in',
              }
            }}>
              {/* <Typography sx={{ position: 'absolute', color: colors.darkGray, left: 15, top: 15, fontSize: "14px", fontWeight: "bold" }}>On Sale</Typography> */}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{
            width: { xs: "100%", md: "630px" },
            height: { xs: "310px", md: "620px" },
            backgroundSize: "cover",
            objectFit: 'contain',
            cursor: "pointer",
            position: 'relative',
            backgroundImage: `url(${Images.kidsCollection10})`,
            backgroundRepeat: "no-repeat",
            ':hover': {
              backgroundImage: `url(${Images.kidsCollection5})`,
              transition: 'all 0.3s ease-in',
            }
          }}>
            {/* <Typography sx={{ position: 'absolute', color: colors.darkGray, left: 15, top: 15, fontSize: "14px", fontWeight: "bold" }}>On Sale</Typography> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid item xs={12} md={12}>
            <Box sx={{
              width: "100%",
              height: "310px",
              backgroundSize: "cover",
              objectFit: 'contain',
              cursor: "pointer",
              position: 'relative',
              backgroundImage: `url(${Images.kidsCollection7})`,
              backgroundRepeat: "no-repeat",
              ':hover': {
                backgroundImage: `url(${Images.kidsCollection8})`,
                transition: 'all 0.3s ease-in',
              }
            }}>
              {/* <Typography sx={{ position: 'absolute', color: colors.darkGray, left: 15, top: 15, fontSize: "14px", fontWeight: "bold" }}>On Sale</Typography> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={{
              width: "100%",
              height: "310px",
              backgroundSize: "cover",
              objectFit: 'contain',
              cursor: "pointer",
              position: 'relative',
              backgroundImage: `url(${Images.kidsCollection9})`,
              backgroundRepeat: "no-repeat",
              ':hover': {
                backgroundImage: `url(${Images.kidsCollection6})`,
                transition: 'all 0.3s ease-in',
              }
            }}>
              {/* <Typography sx={{ position: 'absolute', color: colors.darkGray, left: 15, top: 15, fontSize: "14px", fontWeight: "bold" }}>On Sale</Typography> */}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default KidsCollections