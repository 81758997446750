import { Add } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Container, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { colors } from 'style/colors'
import Images from 'assets/Images'
import React, { useState } from 'react'

// Color Box Select Styling
const useStyles = makeStyles({
  colorBox: {
    width: "30px",
    height: "30px",
    boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;',
    borderRadius: "5px",
    margin: "0px 10px"
  },
  selectedColorBox: {
    width: "30px",
    height: "30px",
    boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;',
    borderRadius: "5px",
    margin: "5px",
    cursor: "pointer"
  },
  tick: {
    position: "absolute",
    cursor: 'pointer',
    top: 5, left: 5,
    color: "white",
    width: "30px"
  }
})

//  Filter Naming List
const familyName = ['Brand', 'Material', 'Type', 'Neckline', 'Product Features', 'Fit', 'Price']
const brand = ['A4', 'Adidas', 'Allmade', 'Alternative Apparel', 'American Apparel', 'American Giant', 'Augusta']

// Color List
const colorsList = ["#000", "#fff", "#DFFF00", "#FFBF00", "#DE3163", "#6495ED", "#CD5C5C"]

function Filter() {
  // Check Box
  const [selectedCheckBox, setSelectedCheckBox] = useState(false)
  const handleChangeBox = () => {
    setSelectedCheckBox(!selectedCheckBox);
  }
  // Color Platte
  const classes = useStyles()
  const [selectedColor, setSelectedColor] = useState("#000");

  return (
    <Box>
      <Box>
        <Accordion>
          <AccordionSummary expandIcon={<Add sx={{ color: colors.primary }} />}>
            <Typography variant="h6">Color Family</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: "flex", alignItems: "center", flexWrap: 'wrap', my: 2 }}>
              {colorsList.map((item, index) => (
                <Box sx={{ position: "relative" }} key={index}>
                  <Box className={classes.selectedColorBox} sx={{ bgcolor: `${item}` }} onClick={() => {
                    setSelectedColor(`${item}`)
                  }}></Box>
                  {item === selectedColor && <img src={Images.Tick} alt="" className={classes.tick} />}
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
        {[...Array(Number(7))].map((item, index) => (
          <Accordion>
            <AccordionSummary expandIcon={<Add sx={{ color: colors.primary }} />}>
              <Typography variant="h6">{familyName[index]}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {[...Array(Number(6))].map((item, index) => (
                <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => handleChangeBox()}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox
                      // onChange={handleChange}
                      checked={selectedCheckBox === true}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 24 }, width: "100%", color: colors.primary }} />} />
                  </FormGroup>
                  <Typography sx={{ fontSize: 18 }}>{brand[index]}</Typography>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  )
}

export default Filter