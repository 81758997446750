// export default App;
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PublicRoutes } from "routes/routes";
import { colors } from 'style/colors';
import Register from './pages/Auth/Register';
import Login from "./pages/Auth/Login";
import useAuth from './hooks/useAuth';
import Toaster from './component/Toaster/toaster';
import PrivateRoutes from "routes/privateRoutes";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
  },
  // typography: {
  //   fontFamily: [
  //     "SharpSans ,sans-serif",
  //   ].join(','),
  // },
});


function App() {
  const { user } = useAuth();
  const token = localStorage.getItem('jwt')
  console.log("🚀 ~ file: App.js ~ line 31 ~ App ~ user", user)

  return (
    <BrowserRouter>
      <Toaster />
      <ThemeProvider theme={theme}>
        <Routes>
          <Route element={!user ? <Outlet /> : <Navigate to="/" />} >
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Route>

          {PublicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.component}
            />
          ))}

          {/* ========== Private Routes ========== */}
          <Route element={user || token ? <Outlet /> : <Navigate to="/login" />}>
            {PrivateRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>
        </Routes>

      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

