import React, { useState, useEffect } from 'react'
import Layout from './../../layout/Layout';
import { Box, Grid, Typography, IconButton, InputAdornment, OutlinedInput, InputLabel, FormControl, TextField } from '@mui/material';
import images from '../../assets/Images';
import useAuth from './../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { LoadingBtn } from 'component/UI/Button';
import { colors } from './../../style/colors';
import { Error } from 'component/UI/Error';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Service } from 'config/service';
import Swal from 'sweetalert2';
import { ErrorToaster, SuccessToaster } from 'component/Toaster/toaster';

function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  // *Login 
  const { register, handleSubmit, formState: { errors } } = useForm();

  // For API Call
  const loginInfo = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        email: formData.email,
        password: formData.password
      }
      const { status, data, responseCode, message } = await Service.login(obj)
      if (status === true && responseCode === 200) {
        setLoading(false)
        SuccessToaster("Login Successfully")
        login(data.token)
      }
      else {
        Swal.fire({
          text: message,
          icon: 'error',
          confirmButtonColor: colors.primary,
        })
      }

    } catch (error) {
      console.log('file: Login.js => line 14 => Login => error', error)
      ErrorToaster("Something went wrong.")
    } finally {
      setLoading(false)
    }
  };

  // const loginInfo = async (formData) => {
  //   let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMTdmMTQwMzM0OGVkYTQ1ZmZlYTI1MzA5NzhkNWJiNzg5OGRmMjFiZDdiZTBlYjExNDQzMzgxM2Y1NDZmYjQ1MjVkMWZmODFjMDU1MGZhNDQiLCJpYXQiOjE2NjMwNjEwNjYuMDI2MTg3LCJuYmYiOjE2NjMwNjEwNjYuMDI2MTkxLCJleHAiOjE2OTQ1OTcwNjYuMDE5MTQ3LCJzdWIiOiI3Iiwic2NvcGVzIjpbXX0.r1IKlsD5gmWAd9W-1xtMeNmSv3cEJjOF_vjprnGUTp4nvO2gcKP6fG3iV86Sxb97xLUGbu6fKDv9WEpKdpXrImIkxFL3ikGJwmY7IULjym4B0uB6JMjHDPU_vZpcHr8oajbSyqx5bdBFJ-1rKsz3UsCKbCTgry9Ihxmszdczk7RxGQGBlrYg0CNeH-r8nLVSCdnjtcREBt2Ne30sY_c8WMYsc1crFU-GMbRNjxZmz3zwBNcY3BCtavx-dlmil7G-Cq8Szb74z4nLwXejVNw90PJknsQIwyAZdj6LwuYaZLPdwQduPmVTuPUjkX5LyEHcoF0eNRFI3wRGWaYQlPVqatKu86H8_6L9ozQ2tL7GCqFq0ljfJMG-8z76mhkqci4lhPooPDt39IGdk-dHK4yC0EIhlqe5qcxsjtMNsDXDV3LBzRu5iCR6F7JUaHEF5cZQIXI4rxN7kIHfd93BYE7I3x386b-KLXt9Y7q8EHTRNgRsnzjvB3_Str2wJgZcDNAq5MVHBN9F7XyHFmGQrpRiznjtWseMo0QoiGlIj7hiY00tHuxAGHelL3keSaa8o1aMdGPkdpvBvLYN-2fNkhFycV7XQSiulzC-t7GeuGtU_gt2Jm_iGv_Pt_wPy_v1DRc6z2toK97xxYIFzuawAToYk_69LOsRS2MhiGKtwMePRp8';
  //   setLoading(true)
  //   login(token)
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 1000)
  //   navigate('/')
  // }

  const [showPassword, setShowPassword] = useState(false);


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])


  return (
    <Layout>
      <Box className="login" sx={{
        display: "flex", alignItems: "center", textAlign: "center",
        backgroundImage: `url(${images.loginBg})`, minHeight: "75vh",
      }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={4} sx={{ border: `1px solid black`, borderRadius: "12px", my: 4 }}>
            <Box sx={{
              textAlign: "center", px: 3, py: 4, backgroundColor: colors.white, borderRadius: "10px",
            }}>
              <img src={images.logo} width="50%" alt="Logo" />
              <Typography variant="h5"
                sx={{ color: colors.black, mt: 3 }}>
                Login
              </Typography>
              <Typography component={"form"} onSubmit={handleSubmit(loginInfo)}>
                <Box sx={{ mt: 2, display: "flex", flexDirection: 'column' }}>
                  <FormControl variant="outlined" sx={{ my: 1 }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Email"
                      type="email"
                      error={errors?.email?.message && (true)}
                      {...register("email", {
                        required: 'Please enter your email.',
                      })}
                    />
                    {errors?.email?.message && (
                      <Error message={errors?.email?.message} />
                    )}
                  </FormControl>
                  <FormControl variant="outlined" sx={{ my: 1 }}>
                    <InputLabel error={errors?.password?.message && (true)}>Password</InputLabel>
                    <OutlinedInput
                      label="Password"
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => { handleClickShowPassword() }}
                            sx={{ color: colors.primary }}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      error={errors?.password?.message && (true)}
                      {...register("password", {
                        required: 'Please create your password.',
                      })}
                    />
                    {errors?.password?.message && (
                      <Error message={errors?.password?.message} />
                    )}
                  </FormControl>

                </Box>
                <Typography
                  onClick={() => { navigate('/register') }}
                  sx={{
                    color: colors.textSecondary, my: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  Don't have an account ? <span style={{ color: colors.primary }}>Create Account</span>
                </Typography>
                <LoadingBtn
                  fullWidth
                  variant="contained"
                  type="submit"
                  loading={loading}
                  sx={{
                    p: 1,
                    my: 1,
                    width: '100%'

                  }}
                >
                  Login
                </LoadingBtn>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default Login