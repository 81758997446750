import React from 'react';

import Layout from 'layout/Layout';

// *Import Component


function MyAccount() {

  return (
    <div id="MyAccount">

      <Layout>

        {/* ========== My Account ========== */}

      </Layout>

    </div>
  );
}

export default MyAccount;