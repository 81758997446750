import moment from "moment";

// *Current Year
export const CurrentYear = () => {
    let date = new Date();
    const year = date.getFullYear();
    return year
}

// *Current Date
export const CurrentDate = () => {
    let date = new Date();
    const currentDate = moment(date).format('MM/DD/YYYY');
    return currentDate
}

// *Date Format
export const getDate = (date) => {
    const newDate = moment(date).format('MM/DD/YYYY')
    return newDate
}

// *Days Difference
export const getDateDifference = (startDate, endDate) => {
    const newStartDate = moment(startDate).format('YYYY-MM-DD')
    const newEndDate = moment(endDate).format('YYYY-MM-DD')
    let a = moment(newEndDate);
    let b = moment(newStartDate);
    let diff = a.diff(b, 'days')
    return diff
}


export const fontFamilyLowerCase = (fontFamily, fontType) => {
    var lowerCase = fontFamily.toLowerCase();
    lowerCase = lowerCase.split(' ').join('-') + '.' + fontType;
    return lowerCase;
};

export const removeSpace = (string) => {
    const name = string.replace(/ /g, '-').toLowerCase();
    return name;
}

export const convertToUpperCase = (string) => {
    const replace = string.replace(/-/g, ' ');
    const name = replace.replace(/-/g, ' ').charAt(0).toUpperCase() + replace.slice(1);
    return name;
}

export const storeCategoryID = (id) => {
    localStorage.removeItem('catID')
    localStorage.setItem('catID', id)
    return true;
}

export const getCategoryIDFromLocalStorage = (id) => {
    const catID = localStorage.getItem('catID')
    return catID;
}

const componentToHex = (c) => {
    let hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export const rgbToHex = (rgb) => {
    var r;
    var g;
    var b;

    if (typeof (rgb) === 'object') {
        r = parseInt(rgb['red']);
        g = parseInt(rgb['green']);
        b = parseInt(rgb['blue']);
    } else {
        var sep = rgb.indexOf(",") > -1 ? "," : " ";
        rgb = rgb.substr(5).split(")")[0].split(sep);

        r = parseInt(rgb[0]);
        g = parseInt(rgb[1]);
        b = parseInt(rgb[2]);
    }

    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const hexToRgb = (hex) => {
    // var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    // return result ? {
    //   red: parseInt(result[1], 16),
    //   green: parseInt(result[2], 16),
    //   blue: parseInt(result[3], 16)
    // } : null;
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
    } : null;
}

export const mergeArray = (array1, array2) => {
    let tempArray = [];
    let finalArray = [];
    array1.forEach((e) => {
        let isHex = /^#([0-9A-F]{3}){1,2}$/i.test(e.code); // *validate hex color
        if (isHex === false) {
            tempArray.push({ color: rgbToHex(e.code) })
        } else {
            tempArray.push({ color: e.code })
        }
    });
    array2.forEach((e) => {
        let isHex = /^#([0-9A-F]{3}){1,2}$/i.test(e); // *validate hex color
        if (isHex === false) {
            tempArray.push({ color: rgbToHex(e) })
        } else {
            tempArray.push({ color: e })
        }
    });
    // *sorting
    tempArray.forEach((e, i) => {
        if (i % 2 === 0) { // index is even
            let color = hexToRgb(e.color);
            finalArray.push(color)
        }
    });
    tempArray.forEach((e, i) => {
        if (i % 2 !== 0) { // index is even
            let color = hexToRgb(e.color);
            finalArray.push(color)
        }
    });

    const filteredArray = removeDuplicateFromArray(finalArray);
    return filteredArray;
}

const removeDuplicateFromArray = (finalArray) => {
    const array = finalArray,
        keys = ['red', 'green', 'blue'],
        filtered = array.filter(
            (s => o =>
                (k => !s.has(k) && s.add(k))
                    (keys.map(k => o[k]).join('|'))
            )
                (new Set)
        );

    return filtered;
}

//*Disable Developer Inspect Tool Options
export const disabledInspect = (time) => {
    console.log(' ');
    // document.onkeydown = function (e) {
    //     if (e.keyCode === 123) {
    //         return false;
    //     }
    //     if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
    //         return false;
    //     }
    //     // if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
    //     //     return false;
    //     // }
    //     if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
    //         return false;
    //     }
    //     if (e.ctrlKey && e.shiftKey && e.keyCode === 'M'.charCodeAt(0)) {
    //         return false;
    //     }
    //     if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
    //         return false;
    //     }
    // }
}

// *Email Regex
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/