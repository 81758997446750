
const images = {

  front: require('./images/front.png').default,
  back: require('./images/back.png').default,
  tick: require('./images/tool-icons/tick.png').default,
  loginBg: require('./images/login-bg.jpg').default,
  mask: require('./images/mask.png').default,

  // * Logos
  logo: require('./images/logos/design-tool-logo.png').default,
  logo2: require('./images/logos/design-tool-alternate.png').default,

  userProfile: require("../assets/images/user-profile.svg").default,
  user: require("../assets/images/user.jpg").default,

  // * Slider
  carousel1: require('./images/slider/slider-1.jpeg').default,
  carousel2: require('./images/slider/slider-2.jfif').default,
  carousel3: require('./images/slider/slider-3.jpg').default,

  // Carousel Images
  slider_one: require('./images/slider1.jpg'),
  slider_two: require('./images/slider2.jpg'),
  slider_three: require('./images/slider3.jpg'),

  // * Mens Collections
  men1: require('./images/mens-collections/men-1.jpg').default,
  men2: require('./images/mens-collections/men-2.jfif').default,
  men3: require('./images/mens-collections/men-3.jpg').default,
  men4: require('./images/mens-collections/men-4.jpg').default,

  // * Womens Collections
  women1: require('./images/womens-collections/women-1.jpg').default,
  women2: require('./images/womens-collections/women-2.jpg').default,
  women3: require('./images/womens-collections/women-3.png').default,
  women4: require('./images/womens-collections/women-4.jpg').default,

  // * Kids Collections
  kid1: require('./images/kids-collections/kid-1.jpg').default,
  kid2: require('./images/kids-collections/kid-2.jpg').default,

  // * Accessories
  accessories1: require('./images/accessories/accessories-1.jfif').default,
  accessories2: require('./images/accessories/accessories-2.png').default,
  accessories3: require('./images/accessories/accessories-3.jfif').default,
  accessories4: require('./images/accessories/accessories-4.jpg').default,
  accessories5: require('./images/accessories/accessories-5.jpg').default,
  accessories6: require('./images/accessories/accessories-6.jpg').default,

  // * Brands
  brand1: require('./images/brands/adidas.jpg').default,
  brand2: require('./images/brands/camelbak.jpg').default,
  brand3: require('./images/brands/carhartt.jpg').default,
  brand4: require('./images/brands/champion.jpg').default,
  brand5: require('./images/brands/columbia.jpg').default,
  brand6: require('./images/brands/hanes.jpg').default,
  brand7: require('./images/brands/marinelayer.jpg').default,
  brand8: require('./images/brands/moleskin.jpg').default,
  brand9: require('./images/brands/nalgene.jpg').default,
  brand10: require('./images/brands/newera.jpg').default,
  brand11: require('./images/brands/nike.jpg').default,
  brand12: require('./images/brands/northface.jpg').default,
  brand13: require('./images/brands/russell.jpg').default,
  brand14: require('./images/brands/underarmour.jpg').default,

  // * Tool Icons
  addName: require('./images/tool-icons/add-name.jpg').default,
  addText: require('./images/tool-icons/add-text.png').default,
  addArt: require('./images/tool-icons/add-art.png').default,
  addNumber: require('./images/tool-icons/add-number.png').default,
  upload: require('./images/tool-icons/upload.png').default,
  garmentColor: require('./images/tool-icons/garment-color.png').default,
  toolAddText: require('./images/tool-icons/tool-add-text.png').default,
  toolAddArt: require('./images/tool-icons/tool-add-art.png').default,
  toolUpload: require('./images/tool-icons/tool-upload.png').default,
  toolChangeProduct: require('./images/tool-icons/tool-change-product.png').default,
  emoji: require('./images/tool-icons/emoji.png').default,
  flipX: require('./images/tool-icons/flip-x.png').default,
  flipY: require('./images/tool-icons/flip-y.png').default,
  topLayer: require('./images/tool-icons/top-layer.png').default,
  bottomLayer: require('./images/tool-icons/bottom-layer.png').default,

  // Product Order
  productOrder: require('./images/productOrder.jpg'),
};

export default images;