import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Email, Facebook, HeadsetMic, LinkedIn, Pinterest, QuestionAnswer, Twitter } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { colors } from 'style/colors';
import { CurrentYear } from 'utils';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: colors.black,
    display: 'block',
    cursor: 'pointer',
    '&:hover': {
      color: colors.white,
      transition: '0.3s ease-in-out',
    },
  },
  navItems: {
    textDecoration: 'none',
    marginLeft: "10px",
    color: colors.secondary,
    padding: "10px",
    borderRadius: "10px",
    "&:hover": {
      color: colors.black,
    }
  },
  facebook: {
    textDecoration: 'none',
    color: colors.secondary,
    margin: "0px 10px",
    "&:hover": {
      color: "#3b5998",
    }
  },
  twitter: {
    textDecoration: 'none',
    color: colors.secondary,
    margin: "0px 10px",
    "&:hover": {
      color: "#00acee",
    }
  },
  linkedin: {
    textDecoration: 'none',
    color: colors.secondary,
    margin: "0px 10px",
    "&:hover": {
      color: "#0e76a8",
    }
  },
  pinterest: {
    textDecoration: 'none',
    color: colors.secondary,
    margin: "0px 10px",
    "&:hover": {
      color: "#c8232c",
    }
  },
});

function Footer() {

  const classes = useStyles();

  return (
    <Fragment>
      <Grid container sx={{ bgcolor: colors.primary, pt: 5, display: "flex", justifyContent: "space-around", p: 3 }} >
        <Grid item xs={12} md={3}>
          <Typography variant="body1" sx={{ color: colors.black, fontWeight: 600, mb: 2 }}>FOLLOW US</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link to={"/"} className={classes.facebook} >
              <Facebook />
            </Link>
            <Link to={"/"} className={classes.twitter}>
              <Twitter />
            </Link>
            <Link to={"/"} className={classes.linkedin}>
              <LinkedIn />
            </Link>
            <Link to={"/"} className={classes.pinterest}>
              <Pinterest />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Typography variant="body2" sx={{ color: colors.black, fontWeight: 'bold', mb: 2 }}>ABOUT US</Typography>
            <Box>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Get to Know Custom Ink</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>The Custom Ink Difference</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Our Story</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Careers</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Press</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Partnerships</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Diversity & Belonging</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Three Hearts Initiative</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Customer Reviews</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Weekly Photo Contest</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Custom Ink Blog</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Store Locations</Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ color: colors.black, fontWeight: 600, mt: 4, mb: 2 }}>YOUR ACCOUNT</Typography>
            <Box>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Retrieve a Saved Design</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Retrieve a Printed Proof</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Track Your Order</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Place a Reorder</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Typography variant="body2" sx={{ color: colors.black, fontWeight: 'bold', mb: 2 }}>TALK TO A REAL PERSON</Typography>
            <Typography variant="body2" sx={{ color: colors.black, fontWeight: 'bold', mb: 2 }}>7 DAYS A WEEK</Typography>
            <Box>
              <Typography variant="body1" sx={{ color: colors.black }}>Monday-Friday: 8am - Midnight ET</Typography>
              <Typography variant="body1" sx={{ color: colors.black }}>Saturday: 10am - 6pm ET</Typography>
              <Typography variant="body1" sx={{ color: colors.black }}>Sunday: 10am - 6pm ET</Typography>
            </Box>
          </Box>
          <Divider sx={{ width: "50px", color: colors.darkGray, p: 1, my: 2 }} />
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
              <HeadsetMic sx={{ color: colors.black, fontSize: "34px", mr: 3 }} />
              <Typography variant="body1" component={Link} to="/" className={classes.link}>+1 703-434-3214</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
              <QuestionAnswer sx={{ color: colors.black, fontSize: "34px", mr: 3 }} />
              <Typography variant="body1" component={Link} to="/" className={classes.link}> Live Chat Now</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
              <Email sx={{ color: colors.black, fontSize: "34px", mr: 3 }} />
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Send us an Email</Typography>
            </Box>
          </Box>
          <Divider sx={{ width: "50px", color: colors.darkGray, p: 1, my: 2 }} />
          <Box>
            <Typography variant="body2" sx={{ color: colors.black, fontWeight: 'bold', mb: 2 }}>SERVICE CENTER</Typography>
            <Box>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Help Center</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Get a Quick Quote</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Content Guidelines</Typography>
              <Typography variant="body1" component={Link} to="/" className={classes.link}>Our Commitment to Accessibility</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12}>
          <Typography variant="body2" color={colors.secondary} sx={{ textAlign: 'center', my: 2 }} >
            © <CurrentYear /> All Rights Reserved. Developed by
            <Link to="https://www.mangotechsolutions.com/" target="_blank" className={classes.link} style={{ color: colors.white }} > MangotechSolutions</Link>
          </Typography>
        </Grid>
      </Grid>
    </Fragment >
  );
}

export default Footer;