import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Menu, MenuItem } from '@mui/material/';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FilterList } from '@mui/icons-material';
import { colors } from 'style/colors';
import { LoadingButton } from "@mui/lab";

//* Imported Components 

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));


export const SortButton = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortValue, setSortValue] = useState("Recommended");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  const handleSortValue = (value) => {
    setSortValue(value);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        {...props}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        startIcon={<FilterList />}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ ...props.myStyle }}
      >
        SORT BY: <span style={{ marginLeft: 2, color: colors.primary }}>{sortValue}</span>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSortValue("Recommended")} sx={{ backgroundColor: sortValue === "Recommended" && colors.platinum }} >
          RECOMMENDED
        </MenuItem>
        <MenuItem onClick={() => handleSortValue("Highest Rated")} sx={{ backgroundColor: sortValue === "Highest Rated" && colors.platinum }} >
          HIGHEST RATED
        </MenuItem>
        <MenuItem onClick={() => handleSortValue("Newest")} sx={{ backgroundColor: sortValue === "Newest" && colors.platinum }} >
          NEWEST
        </MenuItem>
        <MenuItem onClick={() => handleSortValue("Price: Low to High")} sx={{ backgroundColor: sortValue === "Price: Low to High" && colors.platinum }} >
          PRICE: LOW TO HIGH
        </MenuItem>
        <MenuItem onClick={() => handleSortValue("Price: High to Low")} sx={{ backgroundColor: sortValue === "Price: High to Low" && colors.platinum }} >
          PRICE: HIGHT TO LOW
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

export const PrimaryButton = (props) => {

  return (
    <Button variant="contained"
      {...props}
      sx={{
        py: 1,
        px: 3,
        backgroundColor: colors.primary,
        fontSize: { xl: 18, md: 16, xs: 14 },
        textTransform: 'uppercase',
        verticalAlign: 'sub',
        "&:hover": {
          backgroundColor: colors.primary,
        },
        ...props.myStyle
      }}
    >
      {props.children}
    </Button >
  );
}
export const SecondaryButton = (props) => {

  return (
    <Button variant="outlined"
      {...props}
      sx={{
        py: 0.75,
        px: 3,
        border: `1px solid ${colors.primary}`,
        color: colors.primary,
        backgroundColor: colors.white,
        ":hover": {
          border: `1px solid ${colors.primary}`,
        },
        fontSize: { xl: 18, md: 16, xs: 14 },
        textTransform: 'uppercase',
        verticalAlign: 'sub',
        ...props.myStyle
      }}
    >
      {props.children}
    </Button >
  );
}
export const CardButton = (props) => {

  return (
    <Button
      {...props}
      sx={{
        px: 2,
        color: colors.primary,
        backgroundColor: "transparent",
        fontSize: { xl: 16, md: 14, xs: 12 },
        textTransform: 'uppercase',
        verticalAlign: 'sub',
        ...props.myStyle
      }}
    >
      {props.children}
    </Button >
  );
}


export const LoadingBtn = (props) => {

  const { children, type, loading, myStyle, onClick } = props

  return (
    <LoadingButton
      {...props}
      variant="contained"
      type={type}
      loading={loading}
      onClick={onClick}
      sx={{
        ...myStyle,
        py: 1,
        px: 3,
        textTransform: 'capitalize',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
      }}
    >
      {children}
    </LoadingButton>
  );
}