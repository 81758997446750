import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';


// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import '../../../src/index.css';
import { makeStyles } from "@mui/styles";
import Images from "assets/Images";
import { colors } from "style/colors";

const useStyles = makeStyles({

    paginationBullet: {
        background: colors.secondary,
    },
    paginationBulletActive: {
        background: colors.primary,
    },
})

function HomeCarousel() {
    const classes = useStyles();
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    "dynamicBullets": true,
                    clickable: true,
                    bulletClass: classes.paginationBullet + " swiper-pagination-bullet",
                    bulletActiveClass: classes.paginationBulletActive + " swiper-pagination-bullet-active",
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper3"
            >
                <SwiperSlide><img src={Images.slider_one} width="100%" height="500px" alt="slider 1" /></SwiperSlide>
                <SwiperSlide><img src={Images.slider_two} width="100%" height="500px" alt="slider 2" /></SwiperSlide>
                <SwiperSlide><img src={Images.slider_three} width="100%" height="500px" alt="slider 3" /></SwiperSlide>
            </Swiper>
        </>
    )
}

export default HomeCarousel

