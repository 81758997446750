import React, { Fragment } from 'react';
import { TabPanel, TabContext } from '@material-ui/lab';
import { IconButton, List, Typography, TextField, Grid, Divider, Slider, Input, ButtonGroup, CardMedia, Button, Tabs, Tab, FormControlLabel, Switch } from '@material-ui/core';
import { Close, ArrowBackIos, ArrowForwardIos, LockOutlined, LockOpenOutlined, VerticalAlignCenter } from '@mui/icons-material';
import PropagateLoader from 'react-spinners/PropagateLoader';
import images from './../../assets/Image';

function AddArtPanel({ panelLoader, changePanel, loaderColor, showSubCategoriesPanel, categoriesPanel, categories, baseURL,
  getArtWorkSubCatByCat, getArtWorkBySubCat, showSubCategory, showArt, categoryPanelValue, showArtWorkPanel, subCategoryPanelHeading,
  subCategoriesPanel, subCategories, getEditArt, showEditArt, subCategoryPanelValue, showEditArtWorkPanel, artWorkPanelHeading,
  editArtPanel, artWork, changeArtWork, artAlignCenter, frontArtYAxis, flipArtYAxis, frontArtXAxis, flipArtXAxis, productAngle, frontArtRotate,
  backArtRotate, setArtRotateValue, blurRotate, frontArtInvertColor, handleInvertColor, setArtChangeStatus, frontIsArtProportional, artLockUnlockProportional,
  backIsArtProportional, frontArtWork, artIndex, artReset
}) {
  return (
    <Fragment>
      <TabPanel className="art-panel" value="addArt">
        <List component="nav" className="wrapper">
          {/* ========== Panel Loader ========== */}
          {panelLoader && (
            <div>
              <div className="header-title">
                <IconButton
                  aria-label="close"
                  className="close-btn"
                  onClick={() => changePanel("", "main")}
                >
                  <Close />
                </IconButton>

                <Typography
                  variant="body1"
                  component="h1"
                  align="center"
                ></Typography>
              </div>

              <div className="sweet-loading">
                <PropagateLoader
                  color={loaderColor}
                  loading={true}
                  size={15}
                />
              </div>
            </div>
          )}

          {showSubCategoriesPanel && (
            <div className="header-title">
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={() => changePanel("", "main")}
              >
                <Close />
              </IconButton>

              <Typography variant="body1" component="h1" align="center">
                Artwork Categories
              </Typography>
            </div>
          )}

          {/* ========== Category ========== */}
          <TabContext value={categoriesPanel}>
            {/* ========== Category Tabs ========== */}
            {showSubCategoriesPanel && (
              <Tabs
                orientation="vertical"
                value={categoriesPanel}
                className="categories-tab"
              >
                <Tab className="d-none" value="" />
                {categories.map((value, index) => (
                  <Tab
                    key={index}
                    label={value.name}
                    icon={<img src={baseURL + value.image} alt="" />}
                    onClick={() => {
                      getArtWorkSubCatByCat("", value.id, value.name);
                      getArtWorkBySubCat("", "");
                      showSubCategory(false);
                      showArt(true);
                    }}
                    value={value.id.toString()}
                  />
                ))}
              </Tabs>
            )}

            {/* ========== Category Panels ========== */}
            <TabPanel
              className="categories-panel"
              value={categoryPanelValue}
            >
              {showArtWorkPanel && (
                <div className="header-title">
                  <IconButton
                    aria-label="back"
                    className="back-btn"
                    onClick={() => {
                      getArtWorkSubCatByCat("", "", "");
                      showSubCategory(true);
                    }}
                  >
                    <ArrowBackIos />
                  </IconButton>

                  <IconButton
                    aria-label="close"
                    className="close-btn"
                    onClick={() => changePanel("", "main")}
                  >
                    <Close />
                  </IconButton>

                  <Typography
                    variant="body1"
                    component="h1"
                    align="center"
                  >
                    {subCategoryPanelHeading}
                  </Typography>
                </div>
              )}

              {/* ========== Subcategory ========== */}
              <TabContext value={subCategoriesPanel}>
                {/* ========== Subcategory Tabs ========== */}
                {showArtWorkPanel && (
                  <Tabs
                    orientation="vertical"
                    value={subCategoriesPanel}
                    className="subcategories-tab"
                  >
                    <Tab className="d-none" value="" />
                    {subCategories.map((value, index) => (
                      <Tab
                        key={index}
                        label={value.name}
                        onClick={() => {
                          getArtWorkBySubCat(value.id, value.name);
                          getEditArt("", "");
                          showArt(false);
                          showEditArt(true);
                        }}
                        value={value.id.toString()}
                      />
                    ))}
                  </Tabs>
                )}

                {/* ========== Subcategory Panels ========== */}
                <TabPanel
                  className="subcategories-panel"
                  value={subCategoryPanelValue}
                >
                  {showEditArtWorkPanel && (
                    <div className="header-title">
                      <IconButton
                        aria-label="back"
                        className="back-btn"
                        onClick={() => {
                          getArtWorkBySubCat("", "");
                          showArt(true);
                        }}
                      >
                        <ArrowBackIos />
                      </IconButton>

                      <IconButton
                        aria-label="close"
                        className="close-btn"
                        onClick={() => changePanel("", "main")}
                      >
                        <Close />
                      </IconButton>

                      <Typography
                        variant="body1"
                        component="h1"
                        align="center"
                      >
                        {artWorkPanelHeading}
                      </Typography>
                    </div>
                  )}

                  {/* ========== Edit Art ========== */}
                  <TabContext value={editArtPanel}>
                    {/* ========== Art Tool Tabs ========== */}
                    {showEditArtWorkPanel && (
                      <Tabs
                        orientation="vertical"
                        className="artwork-tab"
                        value={editArtPanel}
                      >
                        <Tab className="d-none" value="" />

                        <Grid container spacing={1}>
                          {artWork.map((value, index) => (
                            <Grid item md={4} key={index}>
                              <Tab
                                icon={
                                  <img
                                    src={baseURL + value.image}
                                    alt=""
                                  />
                                }
                                onChange={getEditArt}
                                onClick={() => {
                                  showEditArt(false);
                                  changeArtWork(
                                    value.id,
                                    baseURL + value.image
                                  );
                                }}
                                value="smiley"
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Tabs>
                    )}

                    {/* ========== Art Tool Content ========== */}
                    <TabPanel
                      className="edit-tool-content"
                      value="smiley"
                      style={{ padding: "0px !important" }}
                    >


                      <div className="header-title">
                        <IconButton
                          aria-label="close"
                          className="close-btn"
                          onClick={() => changePanel("", "main")}
                        >
                          <Close />
                        </IconButton>

                        <Typography
                          variant="body1"
                          component="h1"
                          align="center"
                        >
                          Edit Art
                        </Typography>
                      </div>
                      <Grid container spacing={2}>
                        {/* ========== Align Center Button ========== */}
                        <Grid
                          container
                          item
                          md={3}
                          justifyContent="center"
                        >
                          <Button
                            className="center-btn"
                            onClick={() => {
                              artAlignCenter();
                            }}
                          >
                            <VerticalAlignCenter className="center-icon" />
                          </Button>
                          <Typography variant="caption">
                            Center
                          </Typography>
                        </Grid>

                        {/* ========== Flip Button ========== */}
                        <Grid
                          container
                          item
                          md={5}
                          alignItems="center"
                          direction="column"
                        >
                          <ButtonGroup>
                            <Button
                              className={
                                frontArtYAxis === 1
                                  ? ""
                                  : "invert-color color-white"
                              }
                              onClick={() => flipArtYAxis(frontArtYAxis)}
                            >
                              <CardMedia
                                component="img"
                                height="20"
                                image={images.flipY}
                              />
                            </Button>
                            <Button
                              className={
                                frontArtXAxis === 1
                                  ? ""
                                  : "invert-color color-white"
                              }
                              onClick={() => flipArtXAxis(frontArtXAxis)}
                            >
                              <CardMedia
                                component="img"
                                height="20"
                                image={images.flipX}
                              />
                            </Button>
                          </ButtonGroup>
                          <Typography variant="caption">Flip</Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      {/* ========== Rotation ========== */}
                      <Grid container spacing={2} className="rotation">
                        <Grid
                          container
                          item
                          md={3}
                          justifyContent="center"
                        >
                          <Typography variant="body1" component="h6">
                            Rotation
                          </Typography>
                        </Grid>

                        <Grid
                          container
                          item
                          md={7}
                          justifyContent="center"
                        >
                          <Slider
                            // value={frontArtWork[artIndex]?.rotate}
                            value={
                              productAngle === "front"
                                ? frontArtRotate
                                : backArtRotate
                            }
                            // value={artWorkPanel.rotate}
                            onChange={setArtRotateValue}
                            // onChangeCommitted={(e, v) => setArtRotateValueCommitted(e, v)}
                            valueLabelDisplay="auto"
                            min={-180}
                            max={180}
                          />
                        </Grid>

                        <Grid
                          container
                          item
                          md={2}
                          justifyContent="center"
                        >
                          <Input
                            // value={frontArtWork[artIndex]?.rotate}
                            value={
                              productAngle === "front"
                                ? frontArtRotate
                                : backArtRotate
                            }
                            // value={artWorkPanel.rotate}
                            onChange={(e) =>
                              setArtRotateValue(e, e.target.value)
                            }
                            onBlur={blurRotate}
                            inputProps={{
                              step: 1,
                              min: -180,
                              max: 180,
                              type: "number",
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                      {/* ========== Invert Color Button ========== */}
                      <Grid
                        container
                        spacing={2}
                        className="single-color"
                      >
                        <Grid
                          container
                          item
                          md={12}
                          justifyContent="center"
                        >
                          <FormControlLabel
                            value=""
                            control={
                              <Switch
                                checked={frontArtInvertColor}
                                onChange={handleInvertColor}
                              />
                            }
                            label="Invert Art Color"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                      {/* ========== Change Art Button ========== */}
                      <Grid
                        container
                        spacing={2}
                        className="change-art"
                        onClick={() => {
                          getArtWorkSubCatByCat("", "", "");
                          showSubCategory(true);
                          setArtChangeStatus(true);
                        }}
                      >
                        <Typography variant="body1" component="h6">
                          Change Art
                        </Typography>
                        <Typography variant="body1">Change</Typography>
                        <ArrowForwardIos />
                      </Grid>
                      <Divider />
                      {/* ========== Art Size ========== */}
                      <Grid container spacing={2} className="art-size">
                        <Grid item md={4}>
                          <Typography variant="body1" component="h6">
                            Art Size
                          </Typography>
                          <Typography component="p">
                            Width × Height
                          </Typography>
                        </Grid>

                        <Grid
                          container
                          item
                          md={8}
                          justifyContent="flex-end"
                        >
                          {productAngle === "front" && (
                            <div>
                              {frontIsArtProportional && (
                                <IconButton
                                  aria-label="lock"
                                  className="lock-btn"
                                  onClick={() => {
                                    artLockUnlockProportional(false);
                                  }}
                                >
                                  <LockOutlined />
                                </IconButton>
                              )}

                              {frontIsArtProportional === false && (
                                <IconButton
                                  aria-label="lock open"
                                  className="lockOpen-btn"
                                  onClick={() => {
                                    artLockUnlockProportional(true);
                                  }}
                                >
                                  <LockOpenOutlined />
                                </IconButton>
                              )}
                            </div>
                          )}

                          {productAngle === "back" && (
                            <div>
                              {backIsArtProportional && (
                                <IconButton
                                  aria-label="lock"
                                  className="lock-btn"
                                  onClick={() => {
                                    artLockUnlockProportional(false);
                                  }}
                                >
                                  <LockOutlined />
                                </IconButton>
                              )}

                              {backIsArtProportional === false && (
                                <IconButton
                                  aria-label="lock open"
                                  className="lockOpen-btn"
                                  onClick={() => {
                                    artLockUnlockProportional(true);
                                  }}
                                >
                                  <LockOpenOutlined />
                                </IconButton>
                              )}
                            </div>
                          )}

                          <TextField
                            variant="outlined"
                            value={frontArtWork[artIndex]?.artInchWidth}
                            InputProps={{ readOnly: true }}
                          />

                          <Typography variant="body2">in</Typography>
                          <Typography variant="caption">x</Typography>

                          <TextField
                            variant="outlined"
                            value={frontArtWork[artIndex]?.artInchHeight}
                            InputProps={{ readOnly: true }}
                          />

                          <Typography variant="body2">in</Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container spacing={1} className="reset-save">
                        {/* ========== Reset Button ========== */}
                        <Grid
                          container
                          item
                          md={6}
                          justifyContent="flex-start"
                        >
                          <Button
                            variant="outlined"
                            className="reset-btn"
                            onClick={() => {
                              artReset();
                            }}
                          >
                            reset to original
                          </Button>
                        </Grid>

                        {/* ========== Save Button ========== */}
                        <Grid
                          container
                          item
                          md={6}
                          justifyContent="flex-start"
                        >
                          <Button
                            variant="contained"
                            className="save-btn"
                          >
                            save
                          </Button>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </TabPanel>
              </TabContext>
            </TabPanel>
          </TabContext>
        </List>
      </TabPanel>
    </Fragment>
  )
}

export default AddArtPanel