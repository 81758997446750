import React, { Fragment } from 'react';

import { Slider } from '@mui/material';

// *Import Component


function Rotate() {


  const to = (deg) => {
    var
      rad = deg * (Math.PI / 180),
      elm = document.getElementById("rotator"),
      parent = document.getElementById("container");

    console.log(elm.clientHeight + "*sin(" + deg + ") + " + elm.clientWidth + "*cos(" + deg + ") = "
      + (elm.clientHeight * Math.sin(rad) + elm.clientWidth * Math.cos(rad)));

    parent.style.width = (elm.clientHeight * Math.sin(rad) + elm.clientWidth * Math.cos(rad)) + "px";

    elm.style.webkitTransform = 'rotate(' + deg + 'deg)';
    elm.style.mozTransform = 'rotate(' + deg + 'deg)';
    elm.style.msTransform = 'rotate(' + deg + 'deg)';
    elm.style.oTransform = 'rotate(' + deg + 'deg)';
    elm.style.transform = 'rotate(' + deg + 'deg)';

  }

  const setArtRotateValue = (e, value) => {
    to(value)
  }


  return (
    <Fragment>

      <div style={{ padding: '30px' }}>

        <button onClick={() => { to(0) }}>Reset</button>
        <button onClick={() => { to(45) }}>Rotate 45</button>
        <button onClick={() => { to(-45) }}>Rotate -45</button>
        <button onClick={() => { to(90) }}>Rotate 90</button>
        <button onClick={() => { to(66) }}>Rotate 66</button>
        <div style={{ width: "150px", padding: "10px" }}>

          <Slider
            onChange={setArtRotateValue}
            valueLabelDisplay="auto"
            min={-180}
            max={180}
          />
        </div>
        <div id="container">
          <div id="rotator">
            <h1>TEXT</h1>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Rotate;