export const colors = {
  primary: "#FB8122",
  primaryLight: "#fb812224",
  secondary: '#1D2228',
  platinum: '#E1E2E2',
  white: "#fff",
  whiteSpace: '#f0f2f5',
  textSecondary: '#8d9095',
  black: "#424447",
  gray: "#D8D8D8",
  darkGray: "#adadad",
  lightGray: "#e3e3e3",
  pink: "#DE4065",
  gunMetal: '#e5e7ea',
  butterflyBlue: "#2eb6db",
}