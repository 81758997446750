
export const cie76 = (rgb1, rgb2) => {
    const [l1, a1, b1] = _rgb2lab(rgb1)
    const [l2, a2, b2] = _rgb2lab(rgb2)

    const deltaL = l2 - l1;
    const deltaA = a2 - a1;
    const deltaB = b2 - b1;

    const deltaE = deltaL * deltaL + deltaA * deltaA + deltaB * deltaB;
    return deltaE < 0 ? 0 : Math.round(Math.sqrt(deltaE),2);
}

const _rgb2lab = (rgb) => {
    return _xyz2lab(_rgb2xyz(rgb));
}

const _rgb2xyz = (rgb) => {
    const [r, g, b] = rgb

    const color = [
        pivot(r / 255),
        pivot(g / 255),
        pivot(b / 255),
    ]

    return [
        color[0] * 0.412453 + color[1] * 0.357580 + color[2] * 0.180423,
        color[0] * 0.212671 + color[1] * 0.715160 + color[2] * 0.072169,
        color[0] * 0.019334 + color[1] * 0.119193 + color[2] * 0.950227
    ];
}

const _xyz2lab = (xyz) => {
    let [x, y, z] = xyz

    x /= 0.95047;
    y /= 1.0000;
    z /= 1.08883;

    x = x > 0.008856 ? Math.pow(x, 1 / 3) : x * 7.787 + 16 / 116; 
    y = y > 0.008856 ? Math.pow(y, 1 / 3) : y * 7.787 + 16 / 116; 
    z = z > 0.008856 ? Math.pow(z, 1 / 3) : z * 7.787 + 16 / 116; 

    const l = y * 116 - 16;
    const a = (x - y) * 500;
    const b = (y - z) * 200;

    return [ l, a, b ];
}

const pivot = (n) => {
    return (n > 0.04045)
    ? Math.pow((n + 0.055) / (1 + 0.055), 2.4)
    : n / 12.92;
}
