import { createGlobalStyle } from 'styled-components';
import fontURL from 'config/const';

// var fontFamily = 'Arial';
// var lowerCase = 'arial';

//var local = 'http://localhost:3000/fonts/';
//var namecheap = 'https://tool.labvilla.com/fonts/';

// export const getFontFamily = (fontFamily) => {
//     console.log('file: font.js => line 10 => getFontFamily => fontFamily', fontFamily);
//     fontFamily = fontFamily;
//     lowerCase = fontFamily.toLowerCase();
//     lowerCase = lowerCase.split(' ').join('-')+'.ttf';
//     console.log('file: font.js => line 14 => getFontFamily => lowerCase', lowerCase);
// };

// const GlobalFonts = createGlobalStyle`
//     @font-face {
//         font-family: ${fontFamily};
//         src: local('${fontFamily}'),
//         url(${local}+${lowerCase}) format('truetype'),
//         font-weight: 300;
//         font-style: normal;
//     }
// `;

const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: ${props => props.fontName};
        src: url('${fontURL.url}${props => props.lowerCase}') format('${props => props.fontType}');
        font-weight: 300;
        font-style: normal;
    }
`;

export default GlobalFonts;
