import React, { Fragment } from 'react'
import { Grid, Typography, Divider, Box } from '@mui/material';
import { colors } from 'style/colors';
import { Images } from 'assets/Images';
import { useNavigate } from 'react-router-dom';

function Apparel() {

  const navigate = useNavigate();
  const items = [{ name: 'T-Shirts', image: Images.tShirts }, { name: 'Polos', image: Images.polo }, { name: 'Bags', image: Images.bags }, { name: 'Caps', image: Images.caps },
  { name: 'Uniforms', image: Images.uniform }, { name: 'safety', image: Images.safety }, { name: 'businessApparel', image: Images.businessApparel }, { name: 'embroidery', image: Images.embroidery }, { name: 'golf', image: Images.golf },
  { name: 'Jackets', image: Images.Jackets }, { name: 'kids', image: Images.kids }, { name: 'women', image: Images.women }, { name: 'workWear', image: Images.workWear }, { name: 'sustainable', image: Images.sustainable }, { name: 'promotionalProducts', image: Images.promotionalProducts },
  { name: 'promotionalProducts', image: Images.promotionalProducts }, { name: 'premium', image: Images.premium },
  ]

  return (
    <Fragment>
      {/* Shop our Top categories */}
      <Box sx={{ my: 8, backgroundColor: '#F0F2F5', py: 4 }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant='h4' sx={{ color: colors.secondary, fontWeight: "bold", mb: 1 }} >Shop Our Top Categories</Typography>
          <Divider sx={{ mx: "auto", width: "200px", textAlign: "center", bgcolor: colors.primary, py: 0.18, borderRadius: "2px" }} />
          <Typography variant='body2' sx={{ color: colors.textSecondary, my: 2 }}>Our best selling apparel great for any occasion.</Typography>
        </Box>

        <Grid container spacing={2} justifyContent="center">
          {/* Products */}
          {items.map((item, index) => (
            <Grid onClick={() => navigate("/products/t-shirts")} key={index} item xs={12} md={3.5}>
              <Box sx={{
                textAlign: "center", boxShadow: ` rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;`, borderRadius: "6px", cursor: "pointer",
                transition: "0.3s all ease", '&:hover': { boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;` }, backgroundColor: colors.white,
              }}>
                <Typography sx={{ color: colors.black, pt: 3, fontWeight: 500, }}>{item.name}</Typography>
                <img src={item.image} width="100%" alt={item.name} />
              </Box>
            </Grid>
          ))}
          {/* T Shirts */}
          {/* <Grid item xs={12} md={3.5}>
        <Box sx={{
          textAlign: "center", boxShadow: ` rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;`, borderRadius: "6px", cursor: "pointer",
          transition: "0.3s all ease", '&:hover': { boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;` }, backgroundColor: colors.white,
        }}>
          <Typography sx={{ color: colors.black, pt: 3, fontWeight: 500, }}>T-Shirts</Typography>
          <img src={Images.tShirts} width="100%" alt="T-Shirts" />
        </Box>
      </Grid> */}
          {/* Polos */}
          {/* <Grid item xs={12} md={3.5}>
        <Box sx={{
          textAlign: "center", boxShadow: ` rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;`, borderRadius: "6px", cursor: "pointer",
          transition: "0.3s all ease", '&:hover': { boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;` }, backgroundColor: colors.white,
        }}>
          <Typography sx={{ color: colors.black, pt: 3, fontWeight: 500 }}>Polos</Typography>
          <img src={Images.polo} width="100%" alt="Polos" />
        </Box>
      </Grid> */}
          {/* Bags */}
          {/* <Grid item xs={12} md={3.5}>
        <Box sx={{
          textAlign: "center", boxShadow: ` rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;`, borderRadius: "6px", cursor: "pointer",
          transition: "0.3s all ease", '&:hover': { boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;` }, backgroundColor: colors.white,
        }}>
          <Typography sx={{ color: colors.black, pt: 3, fontWeight: 500 }}>Bags</Typography>
          <img src={Images.bags} width="100%" alt="Bags" />
        </Box>
      </Grid> */}
          {/* Caps */}
          {/* <Grid item xs={12} md={3.5}>
        <Box sx={{
          textAlign: "center", boxShadow: ` rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;`, borderRadius: "6px", cursor: "pointer",
          transition: "0.3s all ease", '&:hover': { boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;` }, backgroundColor: colors.white,
        }}>
          <Typography sx={{ color: colors.black, pt: 3, fontWeight: 500 }}>Caps</Typography>
          <img src={Images.caps} width="100%" alt="Caps" />
        </Box>
      </Grid> */}
          {/* Uniforms */}
          {/* <Grid item xs={12} md={3.5}>
        <Box sx={{
          textAlign: "center", boxShadow: ` rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;`, borderRadius: "6px", cursor: "pointer",
          transition: "0.3s all ease", '&:hover': { boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;` }, backgroundColor: colors.white,
        }}>
          <Typography sx={{ color: colors.black, pt: 3, fontWeight: 500 }}>Uniforms</Typography>
          <img src={Images.uniform} width="100%" alt="Uniforms" />
        </Box>
      </Grid> */}
        </Grid>
      </Box >
    </Fragment>
  )
}

export default Apparel