import React, { Fragment } from 'react';
import { Grid, Typography, Divider, Dialog, DialogTitle, DialogContent } from '@mui/material/';
import { Close } from '@mui/icons-material';
import { colors } from 'style/colors';
import images from './../../assets/Image';



function ViewOrder({ handleClose, open, id }) {

  // const [orderDetails, setOrderDetails] = useState([]);

  // //* Get Order Details API
  // const getInvoiceDetails = async () => {
  //   try {
  //     const { Data } = await Service.getInvoiceDetails(id)
  //     setOrderDetails(Data)
  //   } catch (error) {
  //     console.log("🚀 ~ file: ViewOrder.js ~ line 28 ~ //getInvoiceDetails ~ error", error)
  //   }
  // }


  // useEffect(() => {
  //   getInvoiceDetails()
  // }, [id])

  const orderDetails = {
    firstName: "Mr", lastName: "Johnathan", address: "ABC,XYZ", postalCode: 7600, state: "Nazimabad",
    city: "Karachi", country: "Pakistan", invoiceId: "10002", date: "22-Sep, 2022", designName: "My Design", totalPayment: 200, status: "Verified"
  }
  return (
    <Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 3, flexDirection: "row" } }}
      >
        <DialogTitle sx={{
          bgcolor: colors.primary, color: 'white', display: 'flex', justifyContent: 'center'
        }}>
          {/* <img
            src={images.logo}
            alt="Design Tool"
            style={{
              width: '13%',
              mr: 2
            }} /> */}

          <Typography variant='h6' sx={{ my: 'auto', fontWeight: 'bold' }} >
            Order Details</Typography>
          {handleClose &&
            <Close
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 10,
                top: 10,
                color: colors.black,
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            />
          }
        </DialogTitle>
        <DialogContent sx={{ my: 4 }}>
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid item xs={12} md={4}>
              <Typography sx={{ fontWeight: 'bold' }}>Billed to:</Typography>
              <Typography sx={{ color: colors.textPrimary }}>{`${orderDetails.firstName}  ${orderDetails.lastName}`}</Typography>
              <Typography sx={{ color: colors.textPrimary }}>{orderDetails.address}</Typography>
              <Typography sx={{ color: colors.textPrimary }}>{orderDetails.postalCode}</Typography>
              <Typography sx={{ color: colors.textPrimary }}>{orderDetails.state}</Typography>
              <Typography sx={{ color: colors.textPrimary }}>{orderDetails.city}</Typography>
              <Typography sx={{ color: colors.textPrimary }}>{orderDetails.country}</Typography>
            </Grid>
            <Grid item xs={12} md={4} >
              <Typography sx={{ fontWeight: 'bold' }}>Order Number: </Typography>
              <Typography sx={{ color: colors.textPrimary }}>{orderDetails.invoiceId} </Typography>
              <Typography sx={{ mt: 3, fontWeight: 'bold' }}>Date of Issue: </Typography>
              <Typography sx={{ color: colors.textPrimary }}>{orderDetails.date} </Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
              <Typography sx={{ fontWeight: 'bold' }}>Total Payment: </Typography>
              <Typography variant='h5' color={colors.primary}
                sx={{ fontWeight: 'bold' }}
              >${orderDetails.totalPayment}</Typography>
            </Grid>
          </Grid>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '90%',
              padding: '1px',
              margin: 'auto',
              my: 3,
            }}
          />
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid item xs={12} md={8}>
              <Typography sx={{ fontWeight: 'bold' }}>Design Name:</Typography>
              <Typography sx={{ mx: 2, color: colors.textPrimary }}>{orderDetails.designName}</Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
              <Typography sx={{ fontWeight: 'bold' }}>Amount: </Typography>
              <Typography variant='body1' color={colors.primary}
                sx={{ fontWeight: 'bold' }}
              >${orderDetails.totalPayment}</Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ justifyContent: 'space-between', mt: 8 }}>
            <Grid item xs={12} md={8}>
              <Typography variant="body1"
                sx={{ fontWeight: 'bold' }}
              >Order Status:</Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
              <Typography variant='body1'
                sx={{ fontWeight: 'bold', color: orderDetails.status === "Verified" ? "green" : "red" }}
              >{orderDetails.status}</Typography>
            </Grid>
          </Grid>
        </DialogContent>

      </Dialog>
    </Fragment>
  );
}


export default ViewOrder