import DesignTool from "pages/design-tool";
import Home from "pages/Home";
import Login from "pages/login";
import MyAccount from "pages/my-account";
// import ProductCategories from "pages/product-categories";
// import ProductDetail from "pages/product-detail";
// import ProductSubcategories from "pages/product-subcategories";
import Products from "pages/Products/Products";
// import Products from "pages/products";
import Rotate from "pages/rotate";
import StartDesigning from "pages/StartDesigning/StartDesigning";
import SubProduct from "pages/SubProduct/SubProduct";
import Cart from './../pages/Cart/Cart';
import Payment from './../pages/Payment/Payment';


export const PublicRoutes = [
  {
    path: "/login",
    component: <Login />
  },
  {
    path: "/my-account",
    component: <MyAccount />
  },
  {
    path: "/rotate",
    component: <Rotate />
  },
  {
    path: "/",
    component: <Home />
  },
  {
    path: "/products/:id",
    component: <Products />
  },
  {
    path: "/products/:productName/:productCategory",
    component: <SubProduct />
  },
  {
    path: "/products/:productName/:productCategory/:startDesigning",
    component: <StartDesigning />
  },
  {
    path: "/design-tool",
    component: <DesignTool />
  },
  {
    path: "/cart",
    component: <Cart />
  },
  {
    path: "/payment",
    component: <Payment />
  },

]