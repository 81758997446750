import React, { Fragment } from 'react';
import { TabPanel } from '@material-ui/lab';
import { IconButton, List, Typography, TextField, Grid, Divider, Slider, Input, ButtonGroup, CardMedia, Button, FormControlLabel, Switch } from '@material-ui/core';
import { Close, ArrowBackIos, ArrowForwardIos, LockOutlined, LockOpenOutlined, VerticalAlignCenter, CloudUpload } from '@mui/icons-material';
import PropagateLoader from 'react-spinners/PropagateLoader';
import images from './../../assets/Image';

function UploadImagePanel({ panelLoader, changePanel, loaderColor, uploadImg, getImageData, uploadImgPanel, uploadAlignCenter, frontUploadYAxis, flipUploadYAxis, productAngle, backUploadYAxis, frontUploadXAxis,
  flipUploadXAxis, backUploadXAxis, frontUploadRotate, backUploadRotate, setUploadRotateValue, blurRotate, isMakeOneColor, handleMakeOneColor, backgroundRemoveStatus, handleRemoveBackground, getUploadImgPanel,
  selectedOneColor, makeOneColor, renderSwatches, frontIsUploadProportional, uploadLockUnlockProportional, backIsUploadProportional, changeImageWidthSizeValue, frontUploadWPxIntoInches, backUploadWPxIntoInches,
  frontUploadHPxIntoInches, backUploadHPxIntoInches, reset, uploadImgColor, colorLoader, opacity, renderImageColors, selectColor, artColorOptions, rgb, changeFrontImageColor
}) {
  return (
    <Fragment>
      <TabPanel className="upload-panel" value="upload">
        <List component="nav" className="wrapper">
          {/* ========== Uploading Loader ========== */}
          {panelLoader && (
            <div>
              <div className="header-title">
                <IconButton
                  aria-label="close"
                  className="close-btn"
                  onClick={() => changePanel("", "main")}
                >
                  <Close />
                </IconButton>

                <Typography variant="body1" component="h1" align="center">
                  Uploading
                </Typography>
              </div>

              <div className="sweet-loading">
                <PropagateLoader
                  color={loaderColor}
                  loading={true}
                  size={15}
                />
              </div>
            </div>
          )}

          {uploadImg && (
            <div className="header-title">
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={() => changePanel("", "main")}
              >
                <Close />
              </IconButton>

              <Typography variant="body1" component="h1" align="center">
                Choose a file to upload
              </Typography>
            </div>
          )}

          {/* ========== Edit Upload Tab ========== */}
          {uploadImg && (
            <div>
              <div className="upload" onChange={(e) => getImageData(e)}>
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  multiple
                />

                <CardMedia
                  component="img"
                  alt="Upload"
                  height="55"
                  image={images.toolUpload}
                />

                <Typography variant="body2" component="p">
                  Drag & Drop or
                  <br />
                  <Typography variant="body2" component="span">
                    {" "}
                    Browse{" "}
                  </Typography>
                  Your Computer
                </Typography>
              </div>

              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUpload />}
                onChange={(e) => getImageData(e)}
              >
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  multiple
                />
                Upload
              </Button>
            </div>
          )}

          {/* ========== Edit Upload Panel ========== */}
          {uploadImgPanel && (
            <div className="edit-tool-content">
              <div className="header-title">
                <IconButton
                  aria-label="close"
                  className="close-btn"
                  onClick={() => changePanel("", "main")}
                >
                  <Close />
                </IconButton>

                <Typography variant="body1" component="h1" align="center">
                  Edit Upload
                </Typography>
              </div>

              <Grid container spacing={2}>
                <Grid container item md={3} justifyContent="center">
                  <Button
                    className="center-btn"
                    onClick={() => {
                      uploadAlignCenter();
                    }}
                  >
                    <VerticalAlignCenter className="center-icon" />
                  </Button>
                  <Typography variant="caption">Center</Typography>
                </Grid>

                <Grid
                  container
                  item
                  md={5}
                  alignItems="center"
                  direction="column"
                >
                  <ButtonGroup>
                    <Button
                      className={
                        frontUploadYAxis === 1
                          ? ""
                          : "invert-color color-white"
                      }
                      onClick={() =>
                        flipUploadYAxis(
                          productAngle === "front"
                            ? frontUploadYAxis
                            : backUploadYAxis
                        )
                      }
                    >
                      <CardMedia
                        component="img"
                        height="20"
                        image={images.flipY}
                      />
                    </Button>
                    <Button
                      className={
                        frontUploadXAxis === 1
                          ? ""
                          : "invert-color color-white"
                      }
                      onClick={() =>
                        flipUploadXAxis(
                          productAngle === "front"
                            ? frontUploadXAxis
                            : backUploadXAxis
                        )
                      }
                    >
                      <CardMedia
                        component="img"
                        height="20"
                        image={images.flipX}
                      />
                    </Button>
                  </ButtonGroup>
                  <Typography variant="caption">Flip</Typography>
                </Grid>
              </Grid>

              <Divider />

              <Grid container spacing={2} className="rotation">
                <Grid container item md={3} justifyContent="center">
                  <Typography variant="body1" component="h6">
                    Rotation
                  </Typography>
                </Grid>

                <Grid container item md={7} justifyContent="center">
                  <Slider
                    value={
                      productAngle === "front"
                        ? frontUploadRotate
                        : backUploadRotate
                    }
                    onChange={setUploadRotateValue}
                    valueLabelDisplay="auto"
                    min={-180}
                    max={180}
                  />
                </Grid>

                <Grid container item md={2} justifyContent="center">
                  <Input
                    value={
                      productAngle === "front"
                        ? frontUploadRotate
                        : backUploadRotate
                    }
                    onChange={(e) =>
                      setUploadRotateValue(e, e.target.value)
                    }
                    onBlur={blurRotate}
                    inputProps={{
                      step: 1,
                      min: -180,
                      max: 180,
                      type: "number",
                    }}
                  />
                </Grid>
              </Grid>

              <Divider />

              <Grid container spacing={2} className="single-color">
                <Grid container item md={12} justifyContent="center">
                  <FormControlLabel
                    value=""
                    control={
                      <Switch
                        checked={isMakeOneColor}
                        onChange={handleMakeOneColor}
                      />
                    }
                    label="Make One Color"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>

              <Divider />

              {/* ========== Remove Upload Image Background ========== */}
              <Grid container spacing={2} className="single-color">
                <Grid
                  container
                  item
                  md={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormControlLabel
                    value=""
                    control={
                      <Switch
                        checked={backgroundRemoveStatus}
                        onChange={handleRemoveBackground}
                      />
                    }
                    label="Remove Background Color"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>

              <Divider />

              <Grid
                container
                spacing={0}
                className="change-color"
                onClick={() => {
                  getUploadImgPanel(null, null, true);
                }}
              >
                <Grid item md={5}>
                  <Typography variant="body1" component="h6">
                    Edit Color
                  </Typography>
                </Grid>

                {isMakeOneColor && (
                  <Grid item md={7} style={{ textAlign: "right" }}>
                    <Typography
                      component="span"
                      className="image-color"
                      style={{
                        backgroundColor: selectedOneColor,
                      }}
                      onClick={() => {
                        makeOneColor(selectedOneColor);
                      }}
                    />
                    <ArrowForwardIos />
                  </Grid>
                )}

                {isMakeOneColor === false && (
                  <Grid
                    item
                    md={7}
                    style={{
                      textAlign: "right",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        width: "145px",
                        height: "30px",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {renderSwatches()}
                    </div>
                    <ArrowForwardIos />
                    {/* {totalImageColors <= maxColors &&
                            <ArrowForwardIos />
                          } */}
                  </Grid>
                )}
              </Grid>

              <Divider />

              <Grid container spacing={2} className="art-size">
                <Grid item md={4}>
                  <Typography variant="body1" component="h6">
                    Upload Size
                  </Typography>
                  <Typography component="p">Width × Height</Typography>
                </Grid>

                <Grid container item md={8} justifyContent="flex-end">
                  {productAngle === "front" && (
                    <div>
                      {frontIsUploadProportional && (
                        <IconButton
                          aria-label="lock"
                          className="lock-btn"
                          onClick={() => {
                            uploadLockUnlockProportional(false);
                          }}
                        >
                          <LockOutlined />
                        </IconButton>
                      )}

                      {frontIsUploadProportional === false && (
                        <IconButton
                          aria-label="lock open"
                          className="lockOpen-btn"
                          onClick={() => {
                            uploadLockUnlockProportional(true);
                          }}
                        >
                          <LockOpenOutlined />
                        </IconButton>
                      )}
                    </div>
                  )}

                  {productAngle === "back" && (
                    <div>
                      {backIsUploadProportional && (
                        <IconButton
                          aria-label="lock"
                          className="lock-btn"
                          onClick={() => {
                            uploadLockUnlockProportional(false);
                          }}
                        >
                          <LockOutlined />
                        </IconButton>
                      )}

                      {backIsUploadProportional === false && (
                        <IconButton
                          aria-label="lock open"
                          className="lockOpen-btn"
                          onClick={() => {
                            uploadLockUnlockProportional(true);
                          }}
                        >
                          <LockOpenOutlined />
                        </IconButton>
                      )}
                    </div>
                  )}

                  <TextField
                    variant="outlined"
                    ref={changeImageWidthSizeValue}
                    value={
                      productAngle === "front"
                        ? frontUploadWPxIntoInches
                        : backUploadWPxIntoInches
                    }
                    InputProps={{ readOnly: true }}
                  // onChange={(e) => {
                  //   setUploadInches(e.target.value, frontUploadHPxIntoInches);
                  //   getUploadSize({ width: e.target.value, height: frontUploadHPxIntoInches })
                  // }}
                  // handleResizeImageHeightWidth(e.target.value, frontUploadHPxIntoInches) }}
                  />
                  {console.log(
                    "🚀 ~ file: design-tool.js ~ line 3681 ~ DesignTool ~ frontUploadWPxIntoInches",
                    frontUploadWPxIntoInches
                  )}
                  {/*onChange={(e) => { setUploadInches(e.target.value, frontUploadHPxIntoInches) }}*/}
                  {/*InputProps={{ readOnly: true }}*/}
                  {/* {console.log("🚀 ~ file: design-tool.js ~ line 3679 ~ DesignTool ~ frontUploadWPxIntoInches", frontUploadWPxIntoInches)} */}

                  <Typography variant="body2">in</Typography>
                  <Typography variant="caption">x</Typography>

                  <TextField
                    variant="outlined"
                    value={
                      productAngle === "front"
                        ? frontUploadHPxIntoInches
                        : backUploadHPxIntoInches
                    }
                    InputProps={{ readOnly: true }}
                  // onChange={(e) => {
                  //   setUploadInches(frontUploadWPxIntoInches, e.target.value);
                  //   getUploadSize({ width: frontUploadWPxIntoInches, height: e.target.value })
                  // }}
                  // onChange={(e) => { handleResizeImageHeightWidth(frontUploadWPxIntoInches, e.target.value) }}
                  />

                  <Typography variant="body2">in</Typography>
                </Grid>
              </Grid>

              <Divider />

              <Grid container spacing={1} className="reset-save">
                <Grid container item md={6} justifyContent="flex-start">
                  <Button
                    variant="outlined"
                    className="reset-btn"
                    onClick={() => {
                      reset();
                    }}
                  >
                    reset to original
                  </Button>
                </Grid>

                <Grid container item md={6} justifyContent="flex-start">
                  <Button variant="contained" className="save-btn">
                    save
                  </Button>
                </Grid>
              </Grid>
            </div>
          )}

          {uploadImgColor && (
            <div>
              {/* ========== Color Loader ========== */}

              {colorLoader && (
                <div className="color-loader">
                  <div className="sweet-loading">
                    <PropagateLoader
                      color={loaderColor}
                      loading={true}
                      size={15}
                    />
                  </div>
                </div>
              )}

              <div style={{ opacity: opacity }}>
                <div className="header-title">
                  <IconButton
                    aria-label="back"
                    className="back-btn"
                    onClick={() => {
                      getUploadImgPanel(true, false, false); changePanel("", "upload")
                    }}
                  >
                    <ArrowBackIos />
                  </IconButton>

                  <IconButton
                    aria-label="close"
                    className="close-btn"
                    onClick={() => { getUploadImgPanel(true, false, false); changePanel("", "main") }}
                  >
                    <Close />
                  </IconButton>

                  <Typography
                    variant="body1"
                    component="h1"
                    align="center"
                  >
                    Change Color
                  </Typography>
                </div>

                {isMakeOneColor && (
                  <Grid
                    container
                    spacing={1}
                    className="change-color-panel"
                  >
                    <Grid item md={12}>
                      <Typography
                        component="span"
                        className="image-color"
                        style={{
                          backgroundColor: selectedOneColor,
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                {isMakeOneColor === false && (
                  <Grid
                    container
                    spacing={1}
                    className="change-color-panel"
                  >
                    <Typography variant="body1" component="h6">
                      Choose which color to change
                    </Typography>
                    <Grid item md={12}>
                      {renderImageColors()}
                    </Grid>
                  </Grid>
                )}

                <Divider />

                {selectColor && (
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Typography variant="body1" component="h6">
                        Choose a new color
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      {artColorOptions.map((color, index) => (
                        <label className="color-box" key={index}>
                          <input type="radio" name="color" />
                          <span
                            className="checkmark"
                            style={{
                              backgroundColor: rgb(
                                color["red"],
                                color["green"],
                                color["blue"]
                              ),
                            }}
                            onClick={() => {
                              isMakeOneColor === true
                                ? makeOneColor(color)
                                : changeFrontImageColor(color);
                            }}
                          ></span>
                        </label>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          )}
        </List>
      </TabPanel>
    </Fragment>
  )
}

export default UploadImagePanel