import React, { Fragment } from 'react'
import { Grid, Typography } from '@mui/material';
import { Images } from 'assets/Images';
import { PrimaryButton } from 'component/UI/Button';
import { colors } from 'style/colors';

function CTA() {
  return (
    <Fragment>
      <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} >
        <Grid item xs={12} md={5}>
          <img src={Images.template} width="100%" alt="Free Templates" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "28px", fontWeight: "bold", color: colors.black }}>The Largest Collection of <span style={{ color: colors.primary }}> Mockups</span> on the Internet!</Typography>
          <Typography sx={{ fontSize: "16px", color: colors.textSecondary, my: 1.5 }}>Drag and drop your designs to our mockups to create beautiful product shots!</Typography>
          <PrimaryButton>Start Customizing</PrimaryButton>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{ fontSize: "28px", fontWeight: "bold", color: colors.black }}>Thousands of <span style={{ color: colors.primary }}> Free Templates</span> Just for You!</Typography>
          <Typography sx={{ fontSize: "16px", color: colors.textSecondary, my: 1.5 }}>The easiest way to create professional designs for free!
            Choose from our ever changing library of free mockups, designs, videos and logos.</Typography>
          <PrimaryButton>Start Creating Design</PrimaryButton>
        </Grid>
        <Grid item xs={12} md={5}>
          <img src={Images.mockup} width="100%" alt="Mockups" />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default CTA