import React, { Fragment } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';

//* Imported Components
import Images from 'assets/Images'
import { colors } from 'style/colors'
import Faqs from 'component/Faqs/Faqs';
import Breadcrumb from 'component/Breadcrumb/Breadcrumb';
import SearchFilter from 'component/SearchFilter/SearchFilter';
import Layout from 'layout/Layout';


function Products() {

  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Layout>
      {/* Product Section */}
      <Grid container sx={{ p: 3 }}>
        <Grid xs={12} md={12}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2, my: 3, border: `2px solid ${colors.black}`, borderRadius: 2 }}>
            {/* Breadcrumbs */}
            <Breadcrumb path={pathname} />
            <Typography sx={{ color: colors.black, fontWeight: "bold", fontSize: "20px" }}>Design your own custom t-shirts online! T-shirt printing w/ no minimums.</Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={12} sx={{ my: 2, boxShadow: `rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px` }}>
          <SearchFilter />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            onClick={() => navigate("/products/t-shirts/short-sleeves-shirt")}
            sx={{
              width: "100%",
              height: "310px",
              backgroundSize: "cover",
              objectFit: 'contain',
              position: 'relative',
              cursor: "pointer",
              boxShadow: `rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;`,
              backgroundImage: `url(${Images.product1})`,
              backgroundRepeat: "no-repeat",
              ':hover': {
                backgroundImage: `url(${Images.product2})`,
                transition: 'all 0.3s ease-in',
                '&>.MuiTypography-root': {
                  opacity: 0.6,
                  transition: 'all 0.3s ease-in',
                }
              }
            }}
          >
            <Typography sx={{
              position: 'absolute', color: colors.white, left: 15, bottom: 15, fontSize: "16px", backgroundColor: colors.primary, py: 0.75, px: 1, fontWeight: "bold"
            }}>Short Sleeves T-Shirts</Typography>
          </Box>
        </Grid>
        {[...Array(Number(6))].map((item, index) => (
          <Fragment key={index}>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  width: "100%",
                  height: "310px",
                  backgroundSize: "cover",
                  objectFit: 'contain',
                  position: 'relative',
                  cursor: "pointer",
                  boxShadow: `rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;`,
                  backgroundImage: `url(${Images.product3})`,
                  backgroundRepeat: "no-repeat",
                  ':hover': {
                    backgroundImage: `url(${Images.product4})`,
                    transition: 'all 0.3s ease-in',
                    '&>.MuiTypography-root': {
                      opacity: 0.6,
                      transition: 'all 0.3s ease-in',
                    }
                  }
                }}
              >
                <Typography sx={{
                  position: 'absolute', color: colors.white, left: 15, bottom: 15, fontSize: "16px", backgroundColor: colors.primary, py: 0.75, px: 1, fontWeight: "bold"
                }}>Short Sleeves T-Shirts</Typography>
              </Box>
            </Grid>
          </Fragment>))}
      </Grid>
      <Faqs />
    </Layout>
  )
}

export default Products