import axios from 'axios';

export const server = 'https://api-designtool.optech.pk/api/'; // *Live url

const instance = axios.create({
  baseURL: server,
});
instance.interceptors.request.use((request) => {

  // let token = JSON.parse(localStorage.getItem('jwt'))
  let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMTdmMTQwMzM0OGVkYTQ1ZmZlYTI1MzA5NzhkNWJiNzg5OGRmMjFiZDdiZTBlYjExNDQzMzgxM2Y1NDZmYjQ1MjVkMWZmODFjMDU1MGZhNDQiLCJpYXQiOjE2NjMwNjEwNjYuMDI2MTg3LCJuYmYiOjE2NjMwNjEwNjYuMDI2MTkxLCJleHAiOjE2OTQ1OTcwNjYuMDE5MTQ3LCJzdWIiOiI3Iiwic2NvcGVzIjpbXX0.r1IKlsD5gmWAd9W-1xtMeNmSv3cEJjOF_vjprnGUTp4nvO2gcKP6fG3iV86Sxb97xLUGbu6fKDv9WEpKdpXrImIkxFL3ikGJwmY7IULjym4B0uB6JMjHDPU_vZpcHr8oajbSyqx5bdBFJ-1rKsz3UsCKbCTgry9Ihxmszdczk7RxGQGBlrYg0CNeH-r8nLVSCdnjtcREBt2Ne30sY_c8WMYsc1crFU-GMbRNjxZmz3zwBNcY3BCtavx-dlmil7G-Cq8Szb74z4nLwXejVNw90PJknsQIwyAZdj6LwuYaZLPdwQduPmVTuPUjkX5LyEHcoF0eNRFI3wRGWaYQlPVqatKu86H8_6L9ozQ2tL7GCqFq0ljfJMG-8z76mhkqci4lhPooPDt39IGdk-dHK4yC0EIhlqe5qcxsjtMNsDXDV3LBzRu5iCR6F7JUaHEF5cZQIXI4rxN7kIHfd93BYE7I3x386b-KLXt9Y7q8EHTRNgRsnzjvB3_Str2wJgZcDNAq5MVHBN9F7XyHFmGQrpRiznjtWseMo0QoiGlIj7hiY00tHuxAGHelL3keSaa8o1aMdGPkdpvBvLYN-2fNkhFycV7XQSiulzC-t7GeuGtU_gt2Jm_iGv_Pt_wPy_v1DRc6z2toK97xxYIFzuawAToYk_69LOsRS2MhiGKtwMePRp8';
  request.headers = {
    'Accept': "application/json, text/plain, */*",
    'Authorization': `Bearer ${token}`,
  }
  return request
});


export default instance;